<!-- =========================================================================================
  File Name: AboutUs.vue
  Description: AboutUs
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card v-bind:title="$t('MainInfo')" style="margin-bottom: 20px">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Address") }}</label>
          <vs-input
            v-model="SocialLink.Address"
            class="w-full"
            :data-vv-as="$t('Address')"
            name="NameAr"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("PhoneNumber") }}</label>
          <vs-input
            v-model="SocialLink.PhoneNumber"
            class="w-full"
            :data-vv-as="$t('PhoneNumber')"
            name="PhoneNumber"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("MobilPhoneNumber") }}</label>
          <vs-input
            v-model="SocialLink.MobilPhoneNumber"
            class="w-full"
            name="MobilPhoneNumber"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="vs-input--label">{{ $t("Email") }}</label>
          <vs-input
            v-model="SocialLink.Email"
            class="w-full"
            :data-vv-as="$t('Email')"
            name="Email"
          />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("FaceBookAccount") }}</label>
          <vs-input
            v-model="SocialLink.FaceBookAccount"
            class="w-full"
            :data-vv-as="$t('FaceBookAccount')"
            name="FaceBookAccount"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("InstaGramAccount") }}</label>
          <vs-input
            v-model="SocialLink.InstaGramAccount"
            class="w-full"
            :data-vv-as="$t('InstaGramAccount')"
            name="InstaGramAccount"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("WhatsUpNumber") }}</label>
          <vs-input
            v-model="SocialLink.WhatsUpNumber"
            class="w-full"
            :data-vv-as="$t('WhatsUpNumber')"
            name="WhatsUpNumber"
          />
        </div>
      </div>

          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  class="ml-4 mt-2"
                  @click="submitData"
                  >{{ $t("Save") }}
                </vs-button>
                <!-- <vs-button
                  class="ml-4 mt-2"
                  type="border"
                  color="danger"
                  :to="{ name: 'News' }"
                  >{{ $t("Cancel") }}</vs-button
                > -->
              </div>
            </div>
          </div>
    </vx-card>
  </div>
</template>

<script>
import moduleSetting from "@/store/AboutUs/moduleAboutUs.js";

// import axios from "@/axios.js";
export default {
  components: {},
  data() {
    return {
      SocialLink: {
        Address: "",
        PhoneNumber: "",
        MobilPhoneNumber: "",
        Email: "",
        FaceBookAccount: "",
        InstaGramAccount: "",
        WhatsUpNumber: "",
      },
    };
  },
  computed: {},
  methods: {
    submitData() {
      debugger;

      this.$store
        .dispatch("SettingList/updateItem", this.SocialLink)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => this.$vs.loading.close());
    },
  },
  created() {
    if (!moduleSetting.isRegistered) {
      this.$store.registerModule("SettingList", moduleSetting);
      moduleSetting.isRegistered = true;
    }
    //debugger;
    this.$vs.loading.close();
    this.$store
      .dispatch("SettingList/fetchDataListItems")
      .then((res) => {
        this.SocialLink = res.data.data;
        debugger;
        this.$vs.loading.close();
      })
      .catch(() => this.$vs.loading.close());
    this.$vs.loading.close();
  },
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.popup-example {
  border: none !important;
  z-index: 999999;
}
.MapClass {
  width: 640px;
  height: 430px;
}
</style>
