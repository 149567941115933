/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit("UPDATE_VERTICAL_NAV_MENU_WIDTH", width);
  },
  UpdateSelectedCountryCode({ commit }, value) {
    commit("UpdateSelectedCountryCode", value);
  },
  UpdateSelectedCountryName({ commit }, value) {
    commit("UpdateSelectedCountryName", value);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, user) {
    debugger;
    commit("UPDATE_USER_INFO", user);
  },
  GetAllMediaTypes({ commit }) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .get("/API/MediaType/GetAllMediaTypes")
        .then(response => {
          commit("SET_MediaTypes", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  // updateUserRole({ dispatch }, payload) {
  //     // Change client side
  //     payload.aclChangeRole(payload.userRole)

  //     // Make API call to server for changing role

  //     // Change userInfo in localStorage and store
  //     dispatch('updateUserInfo', { userRole: payload.userRole })
  // },
};

export default actions;
