/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth/authService";

//import firebase from "firebase/app";
import "firebase/auth";

Vue.use(Router);

const router = new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("./views/Home.vue"),
          meta: {
            rule: "User"
          }
        },
        //----------------------------------------------------------------------------------
        //Library section
        //----------------------------------------------------------------------------------
        {
          path: "/Library/LibraryAuthor",
          name: "LibraryAuthor",
          component: () =>
            import("@/views/Library/LibraryAuthor/LibraryAuthor.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Library Author" }
            ],
            pageTitle: "Library Author",
            rule: "User"
          }
        },
        {
          path: "/Library/LibraryAuthor-Edit/:ID?",
          name: "LibraryAuthor-Edit",
          component: () =>
            import("@/views/Library/LibraryAuthor/LibraryAuthor-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "LibraryAuthor-Edit" }
            ],
            pageTitle: "LibraryAuthor-Edit",
            rule: "User"
          }
        },
        {
          path: "/Library/LibraryCategory",
          name: "LibraryCategory",
          component: () =>
            import("@/views/Library/libraryCategory/LibraryCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Library Category" }
            ],
            pageTitle: "Library Category",
            rule: "User"
          }
        },
        {
          path: "/Library/LibraryCategory-Edit/:ID?",
          name: "LibraryCategory-Edit",
          component: () =>
            import("@/views/Library/libraryCategory/LibraryCategory-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "LibraryCategory-Edit" }
            ],
            pageTitle: "LibraryCategory-Edit",
            rule: "User"
          }
        },
        {
          path: "/Library/Library",
          name: "Library",
          component: () => import("@/views/Library/library/Library.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Library" }],
            pageTitle: "Library",
            rule: "User"
          }
        },
        {
          path: "/Library-Edit/:ID?",
          name: "Library-Edit",
          component: () =>
            import("@/views/Library/library/Library-edit/Library-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Library-add/Edit" }
            ],
            pageTitle: "Library-add/Edit",
            rule: "User"
          }
        },
        
        //----------------------------------------------------------------------------------
        {
          path: "/News",
          name: "News",
          component: () => import("@/views/news/News.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "News" }],
            pageTitle: "News",
            rule: "User"
          }
        },
        {
          path: "/News-add/Edit/:ID?",
          name: "News-add/Edit",
          component: () => import("@/views/news/News-edit/News-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "News-add/Edit" }
            ],
            pageTitle: "News-add/Edit",
            rule: "User"
          }
        },
        // {
        //     path: '/',
        //     redirect: '/dashboard/analytics'
        // },
        {
          path: "/dashboard/ecommerce",
          name: "dashboard-ecommerce",
          component: () => import("./views/DashboardECommerce.vue"),
          meta: {
            rule: "User"
          }
        },

        //----------------------------------------------------------------------------------
        //sinkar section
        //----------------------------------------------------------------------------------
        {
          path: "/Sinkar/SinkarCategory",
          name: "SinkarCategory",
          component: () =>
            import("@/views/Sinkar/SinkarCategory/SinkarCategory.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "SinkarCategory",
            rule: "User"
          }
        },
        {
          path: "/Sinkar/SinkarCategory-Edit/:ID?",
          name: "SinkarCategory-Edit",
          component: () =>
            import("@/views/Sinkar/SinkarCategory/SinkarCategory-edit.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "SinkarCategory-Edit",
            rule: "User"
          }
        },
        {
          path: "/Sinkar/SinkarContentCategory",
          name: "SinkarContentCategory",
          component: () =>
            import(
              "@/views/Sinkar/SinkarContentCategory/SinkarContentCategory.vue"
            ),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "SinkarContentCategory",
            rule: "User"
          }
        },
        {
          path: "/Sinkar/SinkarContentCategory-Edit/:ID?",
          name: "SinkarContentCategory-Edit",
          component: () =>
            import(
              "@/views/Sinkar/SinkarContentCategory/SinkarContentCategory-edit.vue"
            ),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "SinkarContentCategory-Edit",
            rule: "User"
          }
        },
        {
          path: "/Sinkar/Sinkar",
          name: "Sinkar",
          component: () => import("@/views/Sinkar/Sinkar/Sinkar.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "Sinkar",
            rule: "User"
          }
        },
        {
          path: "/Sinkar/Sinkar-Edit/:ID?",
          name: "Sinkar-Edit",
          component: () =>
            import("@/views/Sinkar/Sinkar/Sinkar-edit/Sinkar-edit.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "Sinkar-edit",
            rule: "User"
          }
        },
        {
          path: "/Sinkar/SinkarRequest",
          name: "SinkarRequest",
          component: () =>
            import("@/views/Sinkar/SinkarRequest/SinkarRequest.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "SinkarRequest",
            rule: "User"
          }
        },
        {
          path: "/Sinkar/SinkarRequest-Details/:ID?",
          name: "SinkarRequest-Details",
          component: () =>
            import("@/views/Sinkar/SinkarRequest/SinkarRequest-Details.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sinkar" }],
            pageTitle: "SinkarRequest-Details",
            rule: "User"
          }
        },
        //----------------------------------------------------------------------------------
        //Sermon section
        //----------------------------------------------------------------------------------
        {
          path: "/Sermon/SermonCategory",
          name: "SermonCategory",
          component: () =>
            import("@/views/Sermon/sermonCategory/SermonCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Sermon Category" }
            ],
            pageTitle: "Sermon Category",
            rule: "User"
          }
        },
        {
          path: "/Sermon/SermonCategory-Edit/:ID?",
          name: "SermonCategory-Edit",
          component: () =>
            import("@/views/Sermon/sermonCategory/SermonCategory-edit.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sermon" }],
            pageTitle: "SermonCategory-Edit",
            rule: "User"
          }
        },
        {
          path: "/Sermon/Sermon",
          name: "Sermon",
          component: () => import("@/views/Sermon/sermon/Sermon.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sermon" }],
            pageTitle: "Sermon",
            rule: "User"
          }
        },
        {
          path: "/Sermon/Sermon-add/Edit/:ID?",
          name: "Sermon-add/Edit",
          component: () =>
            import("@/views/Sermon/sermon/Sermon-edit/Sermon-edit.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Sermon-Edit" }],
            pageTitle: "Sermon-Edit",
            rule: "User"
          }
        },
        //----------------------------------------------------------------------------------
        //Article section
        //----------------------------------------------------------------------------------
        {
          path: "/Article/ArticleCategory",
          name: "ArticleCategory",
          component: () =>
            import("@/views/Article/articleCategory/ArticleCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Article Category" }
            ],
            pageTitle: "Article Category",
            rule: "User"
          }
        },
        {
          path: "/Article/ArticleCategory-add/Edit/:ID?",
          name: "ArticleCategory-Edit",
          component: () =>
            import("@/views/Article/articleCategory/ArticleCategort-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "ArticleCategory-Edit" }
            ],
            pageTitle: "ArticleCategory-Edit",
            rule: "User"
          }
        },
        {
          path: "/Article/Article",
          name: "Article",
          component: () => import("@/views/Article/article/Article.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Article" }],
            pageTitle: "Article",
            rule: "User"
          }
        },
        {
          path: "/Article/Article-add/Edit/:ID?",
          name: "Article-add/Edit",
          component: () =>
            import("@/views/Article/article/Article-edit/Article-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Article-Edit" }
            ],
            pageTitle: "Article-Edit",
            rule: "User"
          }
        },
        //----------------------------------------------------------------------------------
        //Katamars section
        //----------------------------------------------------------------------------------
        {
          path: "/Katamars/KatamarsCategory",
          name: "KatamarsCategory",
          component: () =>
            import("@/views/Katamars/KatamarsCategory/KatamarsCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Katamars Category" }
            ],
            pageTitle: "Katamars Category",
            rule: "User"
          }
        },
        {
          path: "/Katamars/KatamarsCategory-Edit/:ID?",
          name: "KatamarsCategory-Edit",
          component: () =>
            import(
              "@/views/Katamars/KatamarsCategory/KatamarsCategory-edit.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "KatamarsCategory-Edit" }
            ],
            pageTitle: "KatamarsCategory-Edit",
            rule: "User"
          }
        },
        {
          path: "/Katamars/Katamars",
          name: "Katamars",
          component: () => import("@/views/Katamars/Katamars/Katamars.vue"),
          meta: {
            breadcrumb: [{ title: "Home", url: "/" }, { title: "Katamars" }],
            pageTitle: "Katamars",
            rule: "User"
          }
        },
        {
          path: "/Katamars/Katamars-Edit/:ID?",
          name: "Katamars-Edit",
          component: () =>
            import("@/views/Katamars/Katamars/Katamars-edit/Katamars-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Katamars-Edit" }
            ],
            pageTitle: "Katamars-Edit",
            rule: "User"
          }
        },
        {
          path: "/User/Users",
          name: "Users",
          component: () =>
            import("@/views/User/Users.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Users" }
            ],
            pageTitle: "Users",
            rule: "User"
          }
        },
        {
          path: "/User/User-edit/:ID?",
          name: "User-edit",
          component: () =>
            import("@/views/User/User-edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "User-edit" }
            ],
            pageTitle: "User-edit",
            rule: "User"
          }
        },
        //ContactUs section
        //----------------------------------------------------------------------------------
        {
          path: "/ContactUs/Masseges",
          name: "Masseges",
          component: () =>
            import("@/views/ContactUs/Masseges.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Masseges" }
            ],
            pageTitle: "Masseges",
            rule: "User"
          }
        },
        {
          path: "/ContactUs/Complaints",
          name: "Complaints",
          component: () =>
            import("@/views/ContactUs/Complaints.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Complaints" }
            ],
            pageTitle: "Complaints",
            rule: "User"
          }
        },
        {
          path: "/AboutUs",
          name: "AboutUs",
          component: () =>
            import("@/views/AboutUs/index.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "AboutUs" }
            ],
            pageTitle: "AboutUs",
            rule: "User"
          }
        },
        //----------------------------------------------------------------------------------
        {
          path: "/dashboard/ecommerce",
          name: "dashboard-ecommerce",
          component: () => import("./views/DashboardECommerce.vue"),
          meta: {
            rule: "User"
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================

        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "anonymous",
            pageTitle: "Login"
          }
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/pages/register/Register.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/ChangePassword",
          name: "ChangePassword",
          component: () => import("@/views/pages/ChangePassword.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "User"
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  // firebase.auth().onAuthStateChanged(() => {
  //   // get firebase current user
  //   const firebaseCurrentUser = firebase.auth().currentUser;

  // if (
  //     to.path === "/pages/login" ||
  //     to.path === "/pages/forgot-password" ||
  //     to.path === "/pages/error-404" ||
  //     to.path === "/pages/error-500" ||
  //     to.path === "/pages/register" ||
  //     to.path === "/callback" ||
  //     to.path === "/pages/comingsoon" ||
  //     (auth.isAuthenticated() || firebaseCurrentUser)
  // ) {
  //     return next();
  // }

  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    debugger;
    if (!auth.isAuthenticated()) {
      router.push({ path: "/pages/login", query: { to: to.path } });
    }
  }

  return next();
  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth
  // auth.login({ target: to.path });
  // });
});

export default router;
