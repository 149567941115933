<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Users</router-link
        >
      </span>
    </vs-alert>

    <!-- Regist Step -->

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs
          class="tab-action-btn-fill-conatiner"
          style="margin-bottom:220px;"
        >
          <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <add-edit-main class="mt-4" :sinkar="sinkar" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Attachment')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-attachment class="mt-4" :data="sinkar" />
            </div>
          </vs-tab>
          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  class="ml-4 mt-2"
                  @click="save_changes"
                  :disabled="!isFormValid"
                  >{{ $t("Save") }}
                </vs-button>
                <vs-button
                  class="ml-4 mt-2"
                  type="border"
                  color="danger"
                  @click="cancle"
                  >{{ $t("Cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import AddEditMain from "./Add-Edit-Main.vue";
import AddEditAttachment from "./Add-Edit-Attachment.vue";
// Store Module
import moduleSinkar from "@/store/Sinkar/Sinkar/moduleSinkar.js";

export default {
  components: {
    AddEditMain,
    AddEditAttachment
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.sinkar.NameAr;
    },

    validateAttachmentForm() {
      debugger;
      return this.attachment.AttachmentURL == undefined;
    },
    getlistOfAttachment() {
      debugger;
      return this.sinkar.Attachments;
    }
  },

  data() {
    return {
      sinkar: {
        // nameAr: String,
        // nameEn: String,
        // PersonalImage: String,
        // classID: 0,
        // StageID: 0,
        // StageYearID: 0,
        // IsActive: 0,

        Attachments: []
      },

      user_not_found: false,
      activeTab: 0
    };
  },
  watch: {},
  methods: {
    reset_data() {
      this.initValues();
    },
    cancle() {
      this.$router.push({ name: "Sinkar" });
    },

    save_changes() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.sinkar.ID !== null && this.sinkar.ID >= 0) {
            this.$store.dispatch("SinkarList/updateItem", this.sinkar);
            this.initValues();
            this.$router.push("/Sinkar/Sinkar");
          } else {
            this.$store.dispatch("SinkarList/addItem", this.sinkar);
            this.initValues();
            this.$router.push("/Sinkar/Sinkar");
          }
        }
      });
    },

    initValues() {
      this.sinkar = {
        Attachments: []
      };
    }
  },

  created() {
    if (!moduleSinkar.isRegistered) {
      this.$store.registerModule("SinkarList", moduleSinkar);
      moduleSinkar.isRegistered = true;
    }
    debugger;
    // this.getContactTypes();
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();

      this.$store
        .dispatch("SinkarList/GetItemByID", ID)
        .then(res => {
          this.sinkar = res.data.data;
          if (this.sinkar == undefined) {
            this.sinkar = res[0].data;
          }
          this.listOfAttachment = this.sinkar.Attachments;
          this.$vs.loading.close();
        })
        .catch(() => {
          // if (err.response.status === 404) {
          this.user_not_found = true;
          return;
          // }
          // this.$vs.loading.close();
        });
      this.$vs.loading.close();
    }
    this.$vs.loading.close();
  }
};
</script>
