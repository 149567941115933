<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div>
      <div class="vx-row">
        <div class="vx-row w-full">
          <vx-card
            v-bind:title="$t('ArticleCategory')"
            class="mb-base ml-5 mr-5"
          >
            <div class="vx-col">
              <div class="vx-row flex ">
                <div class="flex-1" style="padding-left:5px">
                  <label class="text-sm opacity-75">{{
                    $t("ArticleCategory")
                  }}</label>
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    class="w-full"
                    label="Name"
                    v-model="ArticleCategory.ParentArticleCategoryID"
                    :options="ArticleCategories"
                    autocomplete
                    :reduce="value => value.ID"
                  />
                </div>
                <div class="flex-1" style="padding-left:5px">
                  <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
                  <vs-input
                    v-model="ArticleCategory.NameAr"
                    class="w-full"
                    name="name"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('name')"
                    >{{ errors.first("name") }}</span
                  >
                </div>
                <div class="flex-1" style="padding-left:5px">
                  <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
                  <vs-input
                    v-model="ArticleCategory.NameEn"
                    class="w-full"
                    name="name"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('name')"
                    >{{ errors.first("name") }}</span
                  >
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="mr-6"
              @click="submitData"
              :disabled="!isFormValid"
              >{{ $t("Save") }}</vs-button
            >
            <vs-button
              type="border"
              color="danger"
              :to="{ name: 'ArticleCategory' }"
              >{{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleArticleCategory from "@/store/Article/articleCategory/moduleArticleCategory.js";
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      ArticleCategory: {
        NameEn: "",
        NameAr: ""
      }
    };
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.ArticleCategory.NameAr &&
        this.ArticleCategory.NameEn
      );
    },
    ArticleCategories() {
      return this.$store.state.ArticleCategoryList.ArticleCategories;
    }
  },
  methods: {
    submitData() {
      this.$vs.loading();

      this.$validator.validateAll().then(result => {
        if (result) {
          if (
            this.ArticleCategory.ID !== null &&
            this.ArticleCategory.ID >= 0
          ) {
            this.$store
              .dispatch("ArticleCategoryList/updateItem", this.ArticleCategory)
              .then(() => {
                this.$vs.loading.close();
              })
              .catch(() => {
                this.$vs.loading.close();

                //console.error(err);
              });

            this.$router.push("/Article/ArticleCategory");
          } else {
            this.$store
              .dispatch("ArticleCategoryList/addItem", this.ArticleCategory)
              .then(() => {
                this.$vs.loading.close();
              })
              .catch(() => {
                this.$vs.loading.close();

                //console.error(err);
              });

            this.$router.push("/Article/ArticleCategory");
          }
        }
      });
    }
  },
  created() {
    if (!moduleArticleCategory.isRegistered) {
      this.$store.registerModule("ArticleCategoryList", moduleArticleCategory);
      moduleArticleCategory.isRegistered = true;
    }
    this.$store.dispatch("ArticleCategoryList/fetchDataListItems");

    const ID = parseInt(this.$route.params.ID);
    //debugger;
    if (ID != undefined) {
      this.$vs.loading();

      this.$store
        .dispatch("ArticleCategoryList/GetItemByID", ID)
        .then(res => {
          this.ArticleCategory = res.data.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          //console.error(err);
          this.$vs.loading.close();
        });
      this.$vs.loading.close();
    }
    this.$vs.loading.close();
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
