<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div>
      <div class="vx-row">
        <div class="vx-row w-full">
          <vx-card v-bind:title="$t('LibraryAuthor')" class="mb-base ml-5 mr-5">
            <div class="vx-col">
              <div class="vx-row flex">
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{
                    $t("ParentAuthor")
                  }}</label>
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    label="Name"
                    class="w-full"
                    v-model="LibraryAuthor.ParentAuthorID"
                    :options="LibraryAuthors"
                    autocomplete
                    :reduce="(value) => value.ID"
                  />
                </div>
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
                  <vs-input
                    v-model="LibraryAuthor.NameAr"
                    v-validate="'required'"
                    class="w-full"
                    name="NameAr"
                    :data-vv-as="$t('NameAr')"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('NameAr')"
                    >{{ errors.first("NameAr") }}</span
                  >
                </div>
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
                  <vs-input
                    v-model="LibraryAuthor.NameEn"
                    :data-vv-as="$t('NameEn')"
                    class="w-full"
                    v-validate="'required'"
                    name="NameEn"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('NameEn')"
                    >{{ errors.first("NameEn") }}</span
                  >
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="mr-6"
              @click="submitData"
              :disabled="!isFormValid"
              >{{ $t("Save") }}</vs-button
            >
            <vs-button
              type="border"
              color="danger"
              :to="{ name: 'LibraryAuthor' }"
              >{{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
      <!-- <div class="vx-row">
        <tree-view :data="treeDataList" />
      </div> -->
    </div>
  </div>
</template>

<script>
import moduleLibraryAuthor from "@/store/Library/libraryAuthor/moduleLibraryAuthor.js";
import vSelect from "vue-select";
import Vue from "vue";
import TreeView from "vue-json-tree-view";
Vue.use(TreeView);

export default {
  components: {
    vSelect,
    // Treeselect,
    // VuetifyDraggableTreeview,
  },
  data() {
    return {
      LibraryAuthor: {
        NameEn: "",
        NameAr: "",
      },
      treeData: [],
    };
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.LibraryAuthor.NameAr &&
        this.LibraryAuthor.NameEn
      );
    },
    LibraryAuthors() {
      debugger;
      return this.$store.state.LibraryAuthorList.LibraryAuthors;
    },
    treeDataList() {
      return this.$store.state.LibraryAuthorList.LibraryAuthorsTree;
    },
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.LibraryAuthor.ID !== null && this.LibraryAuthor.ID >= 0) {
            this.$vs.loading();
            this.$store
              .dispatch("LibraryAuthorList/updateItem", this.LibraryAuthor)
              .then(() => this.$vs.loading.close())
              .catch(() => this.$vs.loading.close());
            this.$router.push("/Library/LibraryAuthor");
          } else {
            this.$vs.loading();
            this.$store
              .dispatch("LibraryAuthorList/addItem", this.LibraryAuthor)
              .then(() => this.$vs.loading.close())
              .catch(() => this.$vs.loading.close());
            this.$router.push("/Library/LibraryAuthor");
          }
        }
      });
    },
  },
  created() {
    debugger;
    if (!moduleLibraryAuthor.isRegistered) {
      this.$store.registerModule("LibraryAuthorList", moduleLibraryAuthor);
      moduleLibraryAuthor.isRegistered = true;
    }

    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined) {
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("LibraryAuthorList/GetItemByID", ID)
        .then((res) => {
          debugger;
          this.LibraryAuthor = res.data.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      this.$vs.loading.close();
    }
    this.LibraryAuthor;
    debugger;
     this.$store.dispatch("LibraryAuthorList/GerAuthorsTree");
    
    //   this.$store
    //     .dispatch("LibraryAuthorList/GerAuthorsTree", ID)
    //     .then((res) => {
    //       debugger;
    //       this.treeData = res.data.data;
    //       debugger;
    //       this.$vs.loading.close();
    //     })
    //     .catch(() => {
    //       this.$vs.loading.close();
    //     });
    //
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
