<!-- =========================================================================================
  File Name: clientView.vue
  Description: client View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/client/pixinvent
========================================================================================== -->

<template>
  <div id="page-client-view">
    <div id="Request-data" class="vx-row w-full">
      <vx-card
        v-bind:title="$t('RequestInformation')"
        class="mb-base"
        id="request-info"
      >
        <div class="vx-col">
          <div class="vx-row flex">
            <div class="vx-col flex-1" id="request-info-col-1">
              <table>
                <tr>
                  <td class="font-semibold">{{ $t("Name") }}</td>
                  <td>
                    {{ sinkarRequest.Name }}
                  </td>
                </tr>
              </table>
            </div>

            <div class="vx-col flex-1" id="request-info-col-2">
              <table>
                <tr>
                  <td class="font-semibold">{{ $t("Date") }}</td>
                  <td>
                    {{
                      sinkarRequest.CreatedDate | formatDate("yyyy/MM/dd HH:mm")
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="vx-row flex">
            <div class="vx-col" id="request-info-1">
              <table>
                <tr>
                  <td class="font-semibold">{{ $t("Description") }}</td>
                  <td>
                    {{ sinkarRequest.Description }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">{{ $t("Text") }}</td>
                  <td>
                    {{ sinkarRequest.Text }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="flex flex-wrap mt-5 mb-3 float-right">
            <vs-button
              type="border"
              color="warning"
              icon-pack="feather"
              @click="Back"
            >
              {{ $t("Back") }}</vs-button
            >
          </div>
        </div>
      </vx-card>
    </div>

    <div class="w-full">
      <div id="data-list-list-view" class="data-list-container">
        <!-- <vs-prompt
          title="New Photo"
          accept-text="Save"
          @cancel="clearAttachmentModal"
          @accept="SaveAttachment"
          @close="clearAttachmentModal"
          :is-valid="validateAttachmentForm"
          :active.sync="activePromptAttachment"
        >
          <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
          >
            <form @submit.prevent>
              <div class="mt-5 vx-row w-full " style="padding-left:20px">
                <div class="vx-col">
                  <label class="vs-input--label">photo</label>
                  <vs-upload
                    automatic
                    :limit="1"
                    :action="uploadUrl"
                    :headers="UploadRequestHeader"
                    @on-success="successUpload"
                  />
                </div>
              </div>
            </form>
          </VuePerfectScrollbar>
        </vs-prompt> -->

        <div>
          <div class="vx-row w-full">
            <div class="vx-col w-full">
              <vx-card v-bind:title="$t('File')">
                <vs-table ref="table">
                  <template slot="thead">
                    <vs-th>{{ $t("Download") }}</vs-th>
                  </template>

                  <template>
                    <tbody>
                      <vs-tr v-if="sinkarRequest.RequestFileURL">
                        <vs-td>
                          <a
                            :href="DownUrl + sinkarRequest.RequestFileURL"
                            download
                            target="_blank"
                            ><feather-icon
                              icon="DownloadIcon"
                              svgClasses="w-5 h-5 hover:text-danger stroke-current"
                              class="ml-2"
                          /></a>
                        </vs-td>
                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </vx-card>

              <br />
              <vx-card v-bind:title="$t('Photos')">
                <vs-table
                  ref="table"
                  pagination
                  :max-items="20"
                  :data="sinkarRequest.Attachments"
                >
                  <template slot="thead">
                    <vs-th>{{ $t("Download") }}</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <tbody>
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td>
                          <a
                            :href="DownUrl + tr.AttachmentURL"
                            download
                            target="_blank"
                            ><feather-icon
                              icon="DownloadIcon"
                              svgClasses="w-5 h-5 hover:text-danger stroke-current"
                              class="ml-2"
                          /></a>
                        </vs-td>
                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </vx-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleSinkarRequest from "@/store/Sinkar/SinkarRequest/moduleSinkarRequest.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      sinkarRequest: {}
    };
  },
  methods: {
    Back() {
      this.$router.go(-1);
    }
  },
  created() {
    debugger;
    if (!moduleSinkarRequest.isRegistered) {
      this.$store.registerModule("SinkarRequestList", moduleSinkarRequest);
      moduleSinkarRequest.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();

      this.$store
        .dispatch("SinkarRequestList/GetItemByID", ID)
        .then(res => {
          this.sinkarRequest = res.data.data;
          if (this.sinkarRequest == undefined) {
            this.sinkarRequest = res[0].data;
          }
          this.listOfAttachment = this.sinkarRequest.Attachments;

          this.$vs.loading.close();
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
          console.error(err);
          this.$vs.loading.close();
        });
      this.$vs.loading.close();
    }
    this.$vs.loading.close();
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-client-view {
  table {
    td {
      vertical-align: baseline !important;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}
</style>
