/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Libraries.unshift(item);
  },
  SET_Libraries(state, Libraries) {
    debugger;
    state.Libraries = Libraries;
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_Library(state, Library) {
    const LibraryIndex = state.Libraries.findIndex(p => p.ID == Library.ID);
    if (LibraryIndex != -1) {
      Object.assign(state.Libraries[LibraryIndex], Library);
    }
  },
  REMOVE_ITEM(state, Library) {
    const ItemIndex = state.Libraries.findIndex(p => p.ID == Library.ID);
    state.Libraries.splice(ItemIndex, 1);
  }
};
