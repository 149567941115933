/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Katamars.unshift(item);
  },
  SET_Katamars(state, Katamars) {
    //debugger;
    state.Katamars = Katamars;
  },
  UPDATE_Katamars(state, Katamars) {
    debugger;
    const KatamarsIndex = state.Katamars.findIndex(p => p.ID == Katamars.ID);
    if (KatamarsIndex != -1) {
      Object.assign(state.Katamars[KatamarsIndex], Katamars);
    }
  },
  REMOVE_ITEM(state, Katamars) {
    const ItemIndex = state.Katamars.findIndex(p => p.ID == Katamars.ID);
    state.Katamars.splice(ItemIndex, 1);
  }
};
