/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    var x = item;
    x;
    debugger
    return new Promise((resolve, reject) => {
      axios
        .post("API/News/AddNews", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  search({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/News/SearchNewsWithPaging", item)
        .then(response => {
          commit("SET_News", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      //debugger;
      axios
        .get("api/News/GetAll")
        .then(response => {
          commit("SET_News", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/News/UpdateNews", item)
        .then(response => {
          commit("UPDATE_News", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/News/GetNews?ID=" + itemid)
        .then(response => {
          commit("UPDATE_News", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removeItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/News/DeleteNews?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
