/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.SermonCategories.unshift(item);
  },
  SET_SermonCategories(state, SermonCategories) {
    debugger;
    state.SermonCategories = SermonCategories;
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_SermonCategory(state, SermonCategory) {
    const SermonCategoryIndex = state.SermonCategories.findIndex(
      p => p.ID == SermonCategory.ID
    );
    if (SermonCategoryIndex != -1) {
      Object.assign(
        state.SermonCategories[SermonCategoryIndex],
        SermonCategory
      );
    }
  },
  REMOVE_ITEM(state, SermonCategory) {
    const ItemIndex = state.SermonCategories.findIndex(
      p => p.ID == SermonCategory.ID
    );
    state.SermonCategories.splice(ItemIndex, 1);
  }
};
