<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card v-bind:title="$t('MainInfo')" style="margin-bottom: 20px">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("LibraryCategory") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="NameEn"
            v-model="library.LibraryCategoryID"
            :options="libraryCategories"
            class="w-full"
            autocomplete
            :reduce="(ID) => ID.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('NameEn')">{{
            errors.first("NameEn")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
          <vs-input
            v-model="library.NameAr"
            class="w-full"
            :data-vv-as="$t('NameAr')"
            name="NameAr"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('NameAr')">{{
            errors.first("NameAr")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
          <vs-input v-model="library.NameEn" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="vs-input--label">{{ $t("Type") }}</label>
          <v-select
            label="label"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="library.MediaTypeID"
            :options="MediaTypes"
            class="w-full"
            autocomplete
            :reduce="(value) => value.value"
          />
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Language") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="library.LibraryLanguageID"
            :options="libraryLanguages"
            class="w-full"
            autocomplete
            :reduce="(value) => value.value"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AuthorName") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="NameEn"
            v-model="library.AuthorID"
            :options="libraryAuthors"
            class="w-full"
            autocomplete
            :reduce="(ID) => ID.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('NameEn')">{{
            errors.first("NameEn")
          }}</span>
        </div>

        <!-- 
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AuthorNameAr") }}</label>
          <vs-input v-model="library.AuthorNameAr" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div> -->

        <!-- <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AuthorNameEn") }}</label>
          <vs-input v-model="library.AuthorNameEn" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div> -->
      </div>

      <div class="vx-row">
        <!-- <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressAr") }}</label>
          <vs-input v-model="library.BookAdressAr" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressEn") }}</label>
          <vs-input v-model="library.BookAdressEn" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div> -->
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("PrinterNumber") }}</label>
          <vs-input
            v-model="library.PrinterNumber"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("IBSN") }}</label>
          <vs-input v-model="library.IBSN" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("DepositNumber") }}</label>
          <vs-input
            v-model="library.DepositNumber"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full" v-if="library.MediaTypeID == 4">
          <label class="text-sm opacity-75">{{ $t("NumberOfPages") }}</label>
          <vs-input
            type="number"
            v-model="library.NumberOfPages"
            class="w-full"
            name="name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('NumberOfPages')"
            >{{ errors.first("NumberOfPages") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("PublishHouseAr") }}</label>
          <vs-input
            v-model="library.PublishHouseAr"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("PublishHouseEn") }}</label>
          <vs-input
            v-model="library.PublishHouseEn"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("PublishDate") }}</label>
          <datepicker
            v-model="library.PublishDate"
            class="vx-col w-full"
            name="PublishDate"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("URL") }}</label>
          <vs-input v-model="library.URL" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('URL')">{{
            errors.first("URL")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("DownloadURL") }}</label>
          <vs-input v-model="library.DownloadURL" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('DownloadURL')">{{
            errors.first("DownloadURL")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("BriefAr") }}</label>
          <vs-textarea v-model="library.BriefAr" class="w-full" name="BriefAr">
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("BriefEn") }}</label>
          <vs-textarea v-model="library.BriefEn" class="w-full" name="BriefEn">
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <div class="img-container w-64 mx-auto flex pt-6" v-if="library.URL">
            <!-- <a :href="DownUrl + library.URL" download target="_blank"> -->
            <a :href="library.URL" download target="_blank">
              <p
                class="btn-lg p-6 font-weight-bold"
                style="
                  font-size: 1.5rem;
                  background-color: #f78500;
                  color: #f2f2f2;
                  border-radius: 8px;
                "
              >
                {{ $t("ReadNow") }}
              </p>
            </a>
          </div>
        </div>
        <!-- <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("IndexAr") }}</label>
          <vs-textarea
            v-model="library.BookIndexAr"
            class="w-full"
            name="BookIndexAr"
          >
          </vs-textarea>
        </div> -->
      </div>

      <!-- <div class="vx-col md:w-1/3 w-full">
        <label class="text-sm opacity-75">{{ $t("IndexEn") }}</label>
        <vs-textarea
          v-model="library.BookIndexEn"
          class="w-full"
          name="BookIndexEn"
        >
        </vs-textarea>
      </div> -->
      <!-- </div> -->
      <div class="vx-col" v-if="library.MediaTypeID == 4">
        <label class="vs-input--label">{{ $t("File") }} </label>
        <div class="vx-row">
          <div>
            <vs-upload
              automatic
              :limit="1"
              :action="uploadUrl"
              :headers="UploadRequestHeader"
              @on-success="successUploadBookFile"
            />
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import moduleLibrary from "@/store/Library/library/moduleLibrary.js";
import moduleLibraryCategory from "@/store/Library/libraryCategory/moduleLibraryCategory.js";
import moduleLibraryAuthor from "@/store/Library/libraryAuthor/moduleLibraryAuthor.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";

// import axios from "@/axios.js";
export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
    Datepicker,
  },
  props: {
    library: {
      type: Object,
      required: true,
    },
    viewLoginInfo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      libraryLanguages: [
        { label: "Arabic", value: 1 },
        { label: "English", value: 2 },
      ],
      MediaTypes: [
        { label: "Video", value: 2 },
        { label: "Audio", value: 3 },
        { label: "Book ", value: 4 },
      ],
      uploadUrl: domain + "API/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      isMail: false,

      DownUrl: domain,
      selected: [],
      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
      // library: {},
      dataForDeleted: {},
      edit: false,
      listOfAttachment: [],
      itemsPerPage: 10,
      isMounted: false,

      rowDataForDelete: {},
      activeConfirm: false,
      attachment: {},
    };
  },
  computed: {
    libraryCategories() {
      return this.$store.state.LibraryCategoryList.LibraryCategories;
    },
    libraryAuthors() {
      return this.$store.state.LibraryAuthorList.LibraryAuthors;
    },
  },
  methods: {
    successUploadBookFile(event) {
      //debugger;
      this.library.URL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment",
      });
    },
  },
  created() {
    if (!moduleLibrary.isRegistered) {
      this.$store.registerModule("LibraryList", moduleLibrary);
      moduleLibrary.isRegistered = true;
    }

    //debugger;
    this.$vs.loading.close();
    if (!moduleLibraryCategory.isRegistered) {
      this.$store.registerModule("LibraryCategoryList", moduleLibraryCategory);
      moduleLibraryCategory.isRegistered = true;
    }

    if (!moduleLibraryAuthor.isRegistered) {
      this.$store.registerModule("LibraryAuthorList", moduleLibraryAuthor);
      moduleLibraryAuthor.isRegistered = true;
    }
    this.$store.dispatch("LibraryCategoryList/fetchDataListItems");
    this.$store.dispatch("LibraryAuthorList/fetchDataListItems");

    this.$vs.loading.close();
  },
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.popup-example {
  border: none !important;
  z-index: 999999;
}
.MapClass {
  width: 640px;
  height: 430px;
}
</style>
