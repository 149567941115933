/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.SinkarContentCategories.unshift(item);
  },
  SET_SinkarContentCategories(state, SinkarContentCategories) {
    debugger;
    state.SinkarContentCategories = SinkarContentCategories;
  },
  UPDATE_SinkarContentCategory(state, SinkarContentCategory) {
    const SinkarCategoryIndex = state.SinkarContentCategories.findIndex(
      p => p.ID == SinkarContentCategory.ID
    );
    if (SinkarCategoryIndex != -1) {
      Object.assign(
        state.SinkarContentCategories[SinkarCategoryIndex],
        SinkarContentCategory
      );
    }
  },
  REMOVE_ITEM(state, SinkarContentCategory) {
    const ItemIndex = state.SinkarContentCategories.findIndex(
      p => p.ID == SinkarContentCategory.ID
    );
    state.SinkarContentCategories.splice(ItemIndex, 1);
  }
};
