<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card v-bind:title="$t('MainInfo')" style="margin-bottom:20px">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("KatamarsCategory") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            v-model="katamars.KatamarsCategoryID"
            :options="KatamarsCategories"
            class="w-full"
            autocomplete
            :reduce="ID => ID.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('NameEn')">{{
            errors.first("NameEn")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
          <vs-input
            v-model="katamars.NameAr"
            class="w-full"
            name="name"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
          <vs-input v-model="katamars.NameEn" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <!-- <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressAr") }}</label>
          <vs-input
            v-model="katamars.KatamarsAdressAr"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressEn") }}</label>
          <vs-input
            v-model="katamars.KatamarsAdressEn"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div> -->

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Date") }}</label>
          <datepicker
            v-model="katamars.KatamarsADDate"
            class="vx-col  w-full"
            name="KatamarsDate"
          ></datepicker>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("CopticDate") }}</label>
          <vs-input v-model="katamars.CopticDate" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("URL") }}</label>
          <vs-input
            v-model="katamars.KatamarsFileURL"
            class="w-full"
            name="name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('KatamarsFileURL')"
            >{{ errors.first("KatamarsFileURL") }}</span
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("TextAr") }}</label>
          <vs-textarea
            v-model="katamars.KatamarsTextAr"
            class="w-full"
            name="KatamarsTextAr"
          >
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("TextEn") }}</label>
          <vs-textarea
            v-model="katamars.KatamarsTextEn"
            class="w-full"
            name="KatamarsTextEn"
          >
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("IndexAr") }}</label>
          <vs-textarea
            v-model="katamars.KatamarsIndexAr"
            class="w-full"
            name="KatamarsIndexAr"
          >
          </vs-textarea>
        </div>
      </div>

      <!-- <div class="vx-row"> -->
      <div class="vx-col md:w-1/3 w-full">
        <label class="text-sm opacity-75">{{ $t("IndexEn") }}</label>
        <vs-textarea
          v-model="katamars.KatamarsIndexEn"
          class="w-full"
          name="KatamarsIndexEn"
        >
        </vs-textarea>
      </div>
      <!-- </div> -->
      <div class="vx-col">
        <label class="vs-input--label">{{ $t("File") }} </label>
        <div class="vx-row">
          <div>
            <vs-upload
              automatic
              :limit="1"
              :action="uploadUrl"
              :headers="UploadRequestHeader"
              @on-success="successUploadBookFile"
            />
          </div>
          <div
            class="img-container w-64 mx-auto flex"
            v-if="katamars.KatamarsFileURL"
          >
            <a :href="DownUrl + katamars.KatamarsFileURL" download target="_blank"
              ><feather-icon
                icon="DownloadIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
            /></a>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import moduleKatamars from "@/store/Katamars/Katamars/moduleKatamars.js";

import moduleKatamarsCategory from "@/store/Katamars/KatamarsCategory/moduleKatamarsCategory.js";

import { domain, mainSetting } from "@/gloabelConstant.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";

// import axios from "@/axios.js";
export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
    Datepicker
  },
  props: {
    katamars: {
      type: Object,
      required: true
    },
    viewLoginInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      katamarsLanguages: [
        { label: "Arabic", value: 1 },
        { label: "English", value: 2 }
      ],
      uploadUrl: domain + "API/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      isMail: false,

      DownUrl: domain,
      selected: [],
      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      // katamars: {},
      dataForDeleted: {},
      edit: false,
      listOfAttachment: [],
      itemsPerPage: 10,
      isMounted: false,

      rowDataForDelete: {},
      activeConfirm: false,
      attachment: {}
    };
  },
  computed: {
    KatamarsCategories() {
      return this.$store.state.KatamarsCategoryList.KatamarsCategories;
    }
  },
  methods: {
    successUploadBookFile(event) {
      //debugger;
      this.katamars.KatamarsFileURL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    }
  },
  created() {
    if (!moduleKatamars.isRegistered) {
      this.$store.registerModule("katamarsList", moduleKatamars);
      moduleKatamars.isRegistered = true;
    }
    //debugger;
    this.$vs.loading.close();
    if (!moduleKatamarsCategory.isRegistered) {
      this.$store.registerModule(
        "KatamarsCategoryList",
        moduleKatamarsCategory
      );
      moduleKatamarsCategory.isRegistered = true;
    }
    this.$store.dispatch("KatamarsCategoryList/fetchDataListItems");
    this.$vs.loading.close();
  }
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.popup-example {
  border: none !important;
  z-index: 999999;
}
.MapClass {
  width: 640px;
  height: 430px;
}
</style>
