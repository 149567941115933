// axios
import axios from "axios";
//const domain = "https://localhost:44338/"
const domain = "https://api.copticlib.org/";
//const domain = "https://api.copticlib.org/";

export default axios.create({
    baseURL: domain
        // You can add your headers here
});
