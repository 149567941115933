<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card v-bind:title="$t('MainInfo')" style="margin-bottom:20px">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("SinkarCategory") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="NameEn"
            v-model="sinkar.SinkarCategoryID"
            :options="SinkarCategories"
            class="w-full"
            autocomplete
            :reduce="ID => ID.ID"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{
            $t("SinkarContentCategory")
          }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="NameEn"
            v-model="sinkar.SinkarContentCategoryID"
            :options="SinkarContentCategories"
            class="w-full"
            autocomplete
            :reduce="ID => ID.ID"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
          <vs-input
            v-model="sinkar.NameAr"
            class="w-full"
            name="name"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
          <vs-input v-model="sinkar.NameEn" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <!--
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressAr") }}</label>
          <vs-input
            v-model="sinkar.SinkarAdressAr"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressEn") }}</label>
          <vs-input
            v-model="sinkar.SinkarAdressEn"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div> -->
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Date") }}</label>
          <datepicker
            v-model="sinkar.SinkarADDate"
            class="vx-col  w-full"
            name="SinkarDate"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("CopticDate") }}</label>
          <vs-input v-model="sinkar.CopticDate" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("URL") }}</label>
          <vs-input v-model="sinkar.SinkarFileURL" class="w-full" name="name" />
          <span
            class="text-danger text-sm"
            v-show="errors.has('SinkarFileURL')"
            >{{ errors.first("SinkarFileURL") }}</span
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("TextAr") }}</label>
          <vs-textarea
            v-model="sinkar.SinkarTextAr"
            class="w-full"
            name="SinkarTextAr"
          >
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("TextEn") }}</label>
          <vs-textarea
            v-model="sinkar.SinkarTextEn"
            class="w-full"
            name="SinkarTextEn"
          >
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("BriefAr") }}</label>
          <vs-textarea
            v-model="sinkar.SinkarBriefAr"
            class="w-full"
            name="SinkarBriefAr"
          >
          </vs-textarea>
        </div>
      </div>

      <!-- <div class="vx-row"> -->
      <div class="vx-col md:w-1/3 w-full">
        <label class="text-sm opacity-75">{{ $t("BriefEn") }}</label>
        <vs-textarea
          v-model="sinkar.SinkarBriefEn"
          class="w-full"
          name="SinkarBriefEn"
        >
        </vs-textarea>
      </div>
      <!-- </div> -->
      <div class="vx-col">
        <label class="vs-input--label">{{ $t("File") }} </label>
        <div class="vx-row">
          <div>
            <vs-upload
              automatic
              :limit="1"
              :action="uploadUrl"
              :headers="UploadRequestHeader"
              @on-success="successUploadBookFile"
            />
          </div>
          <div
            class="img-container w-64 mx-auto flex"
            v-if="sinkar.SinkarFileURL"
          >
            <a :href="DownUrl + sinkar.SinkarFileURL" download target="_blank"
              ><feather-icon
                icon="DownloadIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
            /></a>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import moduleSinkar from "@/store/Sinkar/Sinkar/moduleSinkar.js";
import moduleSinkarCategory from "@/store/Sinkar/SinkarCategory/moduleSinkarCategory.js";
import moduleSinkarContentCategory from "@/store/Sinkar/SinkarContentCategory/moduleSinkarContentCategory.js";

import { domain, mainSetting } from "@/gloabelConstant.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
    Datepicker
  },
  props: {
    sinkar: {
      type: Object,
      required: true
    },
    viewLoginInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      libraryLanguages: [
        { label: "Arabic", value: 1 },
        { label: "English", value: 2 }
      ],
      uploadUrl: domain + "API/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      isMail: false,

      DownUrl: domain,
      selected: [],
      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      // sinkar: {},
      dataForDeleted: {},
      edit: false,
      listOfAttachment: [],
      itemsPerPage: 10,
      isMounted: false,

      rowDataForDelete: {},
      activeConfirm: false,
      attachment: {}
    };
  },
  computed: {
    SinkarCategories() {
      //console.log(this.$store.state.SinkarCategoryList.SinkarCategories);
      return this.$store.state.SinkarCategoryList.SinkarCategories;
    },
    SinkarContentCategories() {
      return this.$store.state.SinkarContentCategoryList
        .SinkarContentCategories;
    }
  },
  methods: {
    successUploadBookFile(event) {
      //debugger;
      this.sinkar.SinkarFileURL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    }
  },
  created() {
    if (!moduleSinkar.isRegistered) {
      this.$store.registerModule("SinkarList", moduleSinkar);
      moduleSinkar.isRegistered = true;
    }

    if (!moduleSinkarCategory.isRegistered) {
      this.$store.registerModule("SinkarCategoryList", moduleSinkarCategory);
      moduleSinkarCategory.isRegistered = true;
    }
    this.$store.dispatch("SinkarCategoryList/fetchDataListItems");

    if (!moduleSinkarContentCategory.isRegistered) {
      this.$store.registerModule(
        "SinkarContentCategoryList",
        moduleSinkarContentCategory
      );
      moduleSinkarContentCategory.isRegistered = true;
    }

    this.$store.dispatch("SinkarContentCategoryList/fetchDataListItems");
    this.$vs.loading.close();
  }
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.popup-example {
  border: none !important;
  z-index: 999999;
}
.MapClass {
  width: 640px;
  height: 430px;
}
</style>
