/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.ArticleCategories.unshift(item);
  },
  SET_ArticleCategories(state, ArticleCategories) {
    debugger;
    state.ArticleCategories = ArticleCategories;
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_ArticleCategory(state, ArticleCategory) {
    const ArticleCategoryIndex = state.ArticleCategories.findIndex(
      p => p.ID == ArticleCategory.ID
    );
    if (ArticleCategoryIndex != -1) {
      Object.assign(
        state.ArticleCategories[ArticleCategoryIndex],
        ArticleCategory
      );
    }
  },
  REMOVE_ITEM(state, ArticleCategory) {
    const ItemIndex = state.ArticleCategories.findIndex(
      p => p.ID == ArticleCategory.ID
    );
    state.ArticleCategories.splice(ItemIndex, 1);
  }
};
