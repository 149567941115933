/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
//import { domain} from '@/gloabelConstant.js'
export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + "User/SaveFoodType", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { id: response.data.data.id })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Account/GetAllUsers")
        .then(response => {
          debugger
          commit("SET_USERS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getAllRoles({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Account/GetAllRoles")
        .then(response => {
          debugger
          commit("SET_ROLES", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  EditUserProfile(item) {
    debugger;
    return new Promise((resolve, reject) => {

      axios
        .post("api/Account/EditProfile", item)
        .then(response => {
          //commit("UPDATE_PRODUCT", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  addUserProfile({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Account/InsertUser", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { id: response.data.data.id })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUserProfileByID(context, item) {
    debugger
    return new Promise((resolve, reject) => {
      axios
        .get("api/Account/GetUserProfileByID?ID=" + item)
        .then(response => {
          debugger
          resolve(response);
        })
        .catch(error => {
          debugger
          reject(error);
        });
      debugger
    });
  },

  SortedBy({ commit }, sortBy) {
    return new Promise((resolve, reject) => {
      axios
        .get(axios.defaults.baseURL + "User/SortedBy?sortBy=" + sortBy)
        // axios.get("https://localhost:44349/User/GetAllByDate?sortBy="+sortBy)
        .then(response => {
          commit("SET_PRODUCTS", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post(
          axios.defaults.baseURL +
          "User/EditProfile?UserId=" +
          item.Id +
          "&showInHome=" +
          item.ShowInHome +
          "&orderNumber=" +
          item.OrderNumber
        )
        .then(response => {
          commit("UPDATE_PRODUCT", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + "User/GetFoodTypeByID", itemid)
        .then(response => {
          commit("UPDATE_PRODUCT", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("api/Account/deleteUser?Email=" + item)
        .then(response => {
          commit("REMOVE_ITEM", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  search({ commit }, data) {
    // return new Promise((resolve, reject) => {
    axios
      .post(axios.defaults.baseURL + "User/SearchUserList", data)
      // axios.post("https://localhost:44349/User/SearchUserList",data )
      .then(response => {
        commit("SET_PRODUCTS", response.data.data);
        // resolve(response)
      });
    // .catch((error) => { reject(error) })
    // })
  },
  setUserInActive(id, data) {
    return new Promise(reject => {
      axios
        .get(axios.defaults.baseURL + "User/ToggleUserActivation", {
          params: { userId: data.params.userId, isActive: data.params.IsActive }
        })
        // .then((response) => {
        // console.log(response);

        // commit('UPDATE_PRODUCT',  response.data)
        // resolve(response)
        // })
        .catch(error => {
          reject(error);
        });
    });
  },

};
