/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
    addItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/LibraryCategory/AddLibraryCategory", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    //{ commit }
    fetchDataListItems({ commit }) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get("api/LibraryCategory/GetAll")
                .then(response => {
                    debugger
                    commit("SET_LibraryCategories", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    updateItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/LibraryCategory/UpdateLibraryCategory", item)
                .then(response => {
                    commit("UPDATE_LibraryCategory", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetItemByID(context, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/LibraryCategory/GetLibraryCategory?ID=" + itemid)
                .then(response => {
                    // commit("UPDATE_LibraryCategory", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    removeItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/LibraryCategory/DeleteLibraryCategory?ID=" + item.ID)
                .then(response => {
                    commit("REMOVE_ITEM", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GerCategoryTree({ commit }) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get("api/LibraryCategory/getAllCategoryTree")
                .then(response => {


                    debugger
                    commit("SET_LibraryCategoriesTree", response.data.data);
                    debugger
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};