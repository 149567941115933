<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/forgot-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              v-show="done == false"
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">{{ $t("Recoveryourpassword") }}</h4>
                  <p>
                    {{
                      $T(
                        "Pleaseenteryouremailaddressandwe'llsendyouInstructionsOnHowtoresetyourpassword"
                      )
                    }}
                  </p>
                </div>

                <vs-input
                  v-model="Model.email"
                  type="email"
                  label-placeholder="Email"
                  class="w-full mb-8"
                />

                <vs-button
                  type="border"
                  to="/pages/login"
                  class="px-4 w-full md:w-auto"
                  >{{ $T("BackToLogin") }}</vs-button
                >
                <vs-button
                  @click="SendCodeForEmail"
                  :disabled="!validateForm"
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  >{{ $t("sendcodetomail") }}</vs-button
                >
              </div>
            </div>

            <div
              v-show="done == true"
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">
                    {{ $t("pleasecheckyoure-mailanconfirmationsmssenttoyou") }}
                  </h4>
                </div>
                <vs-input
                  v-model="Model.code"
                  label-placeholder="Code"
                  class="w-full mb-8"
                />
                <vs-input
                  v-model="Model.newPassword"
                  label-placeholder="password"
                  class="w-full mb-8"
                />
                <vs-input
                  v-model="ConfirmPassword"
                  label-placeholder="Confirm Password"
                  class="w-full mb-8"
                />
                <div class="flex flex-wrap items-center justify-end">
                  <vs-button @click="ChangePassword" class="ml-auto mt-2">{{
                    $t("SaveChanges")
                  }}</vs-button>
                  <vs-button
                    @click="cancle"
                    class="ml-4 mt-2"
                    type="border"
                    color="warning"
                    >{{ $t("Cancel") }}</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      done: false,
      Model: {},
      ConfirmPassword: ""
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.Model.email !== "";
    },
    validateForm1() {
      return (
        !this.errors.any() &&
        this.Model.code !== "" &&
        this.Model.newPassword !== "" &&
        this.Model.newPassword == this.ChangePassword
      );
    }
  },

  methods: {
    SendCodeForEmail() {
      //debugger;
      this.$store
        .dispatch("auth/SendVerifyCodeForEmail", this.Model.email)
        .then(() => {
          this.done = true;
        })
        .catch(() => {});
    },
    cancle() {
      this.$router.go(-1);
    },
    ChangePassword() {
      //debugger;
      this.$store
        .dispatch("auth/ResetPassword", this.Model)
        .then(() => {
          this.$router.push("/pages/login");
        })
        .catch(() => {});
    }
  },
  created() {
    this.done = false;
  }
};
</script>
