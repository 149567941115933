/*=========================================================================================
  File Name: moduleLibraryAuthorMutations.js
  Description: LibraryAuthor Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_ITEM(state, item) {
        state.LibraryAuthors.unshift(item);
    },
    SET_LibraryAuthors(state, LibraryAuthors) {
        debugger;
        state.LibraryAuthors = LibraryAuthors;
    },
    SET_LibraryAuthorsTree(state, LibraryAuthors) {
        debugger;
        state.LibraryAuthorsTree = LibraryAuthors;
    },
    // // SET_LABELS(state, labels) {
    // //   state.eventLabels = labels
    // // },
    UPDATE_LibraryAuthor(state, LibraryAuthor) {
        const LibraryAuthorIndex = state.LibraryAuthors.findIndex(
            p => p.ID == LibraryAuthor.ID
        );
        if (LibraryAuthorIndex != -1) {
            Object.assign(
                state.LibraryAuthors[LibraryAuthorIndex],
                LibraryAuthor
            );
        }
    },
    REMOVE_ITEM(state, LibraryAuthor) {
        const ItemIndex = state.LibraryAuthors.findIndex(
            p => p.ID == LibraryAuthor.ID
        );
        state.LibraryAuthors.splice(ItemIndex, 1);
    }
};