/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.KatamarsCategories.unshift(item);
  },
  SET_KatamarsCategory(state, KatamarsCategories) {
    //debugger;
    state.KatamarsCategories = KatamarsCategories;
  },
  UPDATE_KatamarsCategory(state, KatamarsCategory) {
    debugger;
    const KatamarsCategoryIndex = state.KatamarsCategories.findIndex(
      p => p.ID == KatamarsCategory.ID
    );
    if (KatamarsCategoryIndex != -1) {
      Object.assign(
        state.KatamarsCategories[KatamarsCategoryIndex],
        KatamarsCategory
      );
    }
  },
  REMOVE_ITEM(state, KatamarsCategory) {
    const ItemIndex = state.KatamarsCategories.findIndex(
      p => p.ID == KatamarsCategory.ID
    );
    state.KatamarsCategories.splice(ItemIndex, 1);
  }
};
