<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="User Not Found"
      :active.sync="user_not_found"
    >
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Users</router-link
        >
      </span>
    </vs-alert>

    <!-- Regist Step -->

    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs
          class="tab-action-btn-fill-conatiner"
          style="margin-bottom:220px;"
        >
          <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <add-edit-main class="mt-4" :sermon="sermon" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Attachment')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-attachment class="mt-4" :data="sermon" />
            </div>
          </vs-tab>
          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  class="ml-4 mt-2"
                  @click="save_changes"
                  :disabled="!isFormValid"
                  >{{ $t("Save") }}
                </vs-button>
                <vs-button
                  class="ml-4 mt-2"
                  type="border"
                  color="danger"
                  @click="cancle"
                  >{{ $t("Cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import AddEditMain from "./Add-Edit-Main.vue";
import AddEditAttachment from "./Add-Edit-Attachment.vue";
// Store Module
import moduleSermon from "@/store/Sermon/sermon/moduleSermon.js";
export default {
  components: {
    AddEditMain,
    AddEditAttachment
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.sermon.NameAr &&
        this.sermon.SermonCategoryID > 0
      );
    },

    validateAttachmentForm() {
      //debugger;
      return this.attachment.AttachmentURL == undefined;
    },
    getlistOfAttachment() {
      //debugger;
      return this.sermon.Attachments;
    }
  },

  data() {
    return {
      sermon: {
        // nameAr: String,
        // nameEn: String,
        // PersonalImage: String,
        // classID: 0,
        // StageID: 0,
        // StageYearID: 0,
        // IsActive: 0,

        Attachments: []
      },

      user_not_found: false,
      activeTab: 0
    };
  },
  watch: {},
  methods: {
    reset_data() {
      this.initValues();
    },
    cancle() {
      this.$router.push({ name: "Sermon" });
    },

    save_changes() {
      //debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.sermon.ID !== null && this.sermon.ID >= 0) {
            this.$store.dispatch("SermonList/updateItem", this.sermon);
            this.initValues();
            this.$router.push("/Sermon/Sermon");
          } else {
            this.$store.dispatch("SermonList/addItem", this.sermon);
            this.initValues();
            this.$router.push("/Sermon/Sermon");
          }
        }
      });
    },

    initValues() {
      this.sermon = {
        Attachments: []
      };
    }
  },

  created() {
    if (!moduleSermon.isRegistered) {
      this.$store.registerModule("SermonList", moduleSermon);
      moduleSermon.isRegistered = true;
    }

    // this.getContactTypes();
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();

      this.$store
        .dispatch("SermonList/GetItemByID", ID)
        .then(res => {
          //debugger;
          this.sermon = res.data.data;
          if (this.sermon == undefined) {
            this.sermon = res[0].data;
          }
          this.listOfAttachment = this.sermon.Attachments;
          //debugger;
          this.$vs.loading.close();
        })
        .catch(err => {
          //debugger;
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
          //console.error(err);
          this.$vs.loading.close();
        });
      this.$vs.loading.close();
    }
  }
};
</script>
