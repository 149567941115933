<template>
  <div id="data-list-list-view" class="data-list-container">
    <div>
      <!-- full info -->
      <div class="vx-row">
        <div class="vx-row w-full">
          <vx-card v-bind:title="$t('AddUser')" class="mb-base ml-5 mr-5">
            <div class="vx-col">
              <!-- input data -->
              <div class="vx-row flex">
                <!-- role select -->
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{ $t("UserRole") }}</label>
                  <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" label="Name" class="w-full" v-model="User.RolesID"
                    :options="RolesList" multiple autocomplete :reduce="(value) => value.Id" />
                </div>
              </div>
              <div class="vx-row flex">
                <!-- displayName -->
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{
                    $t("displayName")
                  }}</label>
                  <vs-input v-model="User.Name" v-validate="'required'" class="w-full mt-6" name="Name"
                    :data-vv-as="$t('displayName')" />
                  <span class="text-danger text-sm">{{
                    errors.first("Name")
                  }}</span>
                </div>

                <!-- Email -->
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{ $t("Email") }}</label>

                  <vs-input v-validate="'required|email'" data-vv-validate-on="blur" name="email" type="email"
                    placeholder="Email" v-model="User.Email" class="w-full mt-6" />
                  <span class="text-danger text-sm">{{
                    errors.first("email")
                  }}</span>
                </div>
              </div>
              <div class="vx-row flex">
                <!-- password -->
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{ $t("password") }}</label>

                  <vs-input ref="password" type="password" data-vv-validate-on="blur" v-validate="'required|min:6|max:10'"
                    name="password" placeholder="Password" v-model="User.password" class="w-full mt-6" />
                  <span class="text-danger text-sm">{{
                    errors.first("password")
                  }}</span>
                </div>
                <!-- ConfirmPassword -->
                <div class="flex-1" style="padding-left: 5px">
                  <label class="text-sm opacity-75">{{
                    $t("ConfirmPassword")
                  }}</label>

                  <vs-input type="password" v-validate="'min:6|max:10|confirmed:password'" data-vv-validate-on="blur"
                    data-vv-as="password" name="confirm_password" placeholder="Confirm Password"
                    v-model="User.confirmPassword" class="w-full mt-6" />
                  <span class="text-danger text-sm">{{
                    errors.first("confirm_password")
                  }}</span>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
      <!-- button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <!-- <vs-button
              class="mr-6"
              @click="submitData"
              :disabled="!isFormValid"
              >{{ $t("Save") }}
              </vs-button
            > -->

            <vs-button class="float-right" @click="registerUserJWt" :disabled="!validateForm">{{ $t("Save") }}
            </vs-button>
            <vs-button type="border" color="danger" :to="{ name: 'Users' }">{{
              $t("Cancel")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleUser from "@/store/user/moduleUser.js";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      User: {},

    };
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.User.Email && this.User.password;
    },
    RolesList() {
      return this.$store.state.UserList.Roles;
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.User.Name != "" &&
        this.User.Email != "" &&
        this.User.password != "" &&
        this.User.confirmPassword != ""
      );
    },
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.User.ID !== null && this.User.ID >= 0) {
            this.$vs.loading();
            this.$store
              .dispatch("UserList/EditUserProfile", this.User)
              .then(() => this.$vs.loading.close())
              .catch(() => this.$vs.loading.close());
            this.$router.push("/User/Users");
          } else {
            this.$store
              .dispatch("UserList/addUserProfile", this.User)
              .then(() => this.$vs.loading.close())
              .catch((Error) => {
                // if (Error.response.status == 404) {
                this.$vs.notify({
                  title: "Error",
                  text: Error.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
                //}
                this.$vs.loading.close();
              });
            this.$router.push("/User/Users");
          }
        }
      });
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm) return;
      debugger;
      this.User;
      debugger;
      this.User.RolesID;
      const payload = {
        userDetails: {
          Role: this.User.RolesID,
          displayName: this.User.Name,
          email: this.User.Email,
          password: this.User.password,
          confirmPassword: this.User.confirmPassword,
        },
        notify: this.$vs.notify,
      };
      payload;
      debugger
      this.$store
        .dispatch("auth/registerUserJWT", payload)
        .then(() => {
          window.showAddSuccess();
          this.$vs.loading.close();
          this.$router.push("/User/Users");
        })
        .catch(() => {
          window.ErrorData();
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
    this.$store.dispatch("UserList/getAllRoles").then(() => {
     
      this.$vs.loading.close();
    });
    this.$vs.loading.close();
    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined) {
     
      this.$store
        .dispatch("UserList/GetUserProfileByID", ID)
        .then((res) => {
         
          this.User = res.data.data;
          if (this.User) {
            this.User.Name = this.User.UserName;
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      this.$vs.loading.close();
    }
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
