/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.News.unshift(item);
  },
  SET_News(state, News) {
    debugger;
    state.News = News;
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_News(state, News) {
    const NewsIndex = state.News.findIndex(p => p.ID == News.ID);
    if (NewsIndex != -1) {
      Object.assign(state.News[NewsIndex], News);
    }
  },
  REMOVE_ITEM(state, News) {
    const ItemIndex = state.News.findIndex(p => p.ID == News.ID);
    state.News.splice(ItemIndex, 1);
  }
};
