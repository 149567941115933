/*=========================================================================================
  File Name: moduleLibraryAuthorActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {

    fetchDataListItems({ commit }) {
        debugger
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get("API/Home/GetGeneralInfo")
                .then(response => {
                    debugger
                    commit("SET_MainInfo", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
            debugger
        });
    },

    updateItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("API/Home/UpdateGeneralInfo", item)
                .then(response => {
                    commit("SET_MainInfo", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },



};