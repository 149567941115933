/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Sermons.unshift(item);
  },
  SET_Sermons(state, Sermons) {
    debugger;
    state.Sermons = Sermons;
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_Sermon(state, Sermon) {
    const SermonIndex = state.Sermons.findIndex(p => p.ID == Sermon.ID);
    if (SermonIndex != -1) {
      Object.assign(state.Sermons[SermonIndex], Sermon);
    }
  },
  REMOVE_ITEM(state, Sermon) {
    const ItemIndex = state.Sermons.findIndex(p => p.ID == Sermon.ID);
    state.Sermons.splice(ItemIndex, 1);
  }
};
