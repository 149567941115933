<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
 
<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-prompt
      title="New Photo"
      accept-text="Save"
      @cancel="clearAttachmentModal"
      @accept="SaveAttachment"
      @close="clearAttachmentModal"
      :is-valid="validateAttachmentForm"
      :active.sync="activePromptAttachment"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent>
          <div class="mt-5 vx-row w-full" style="padding-left: 20px">
            <div class="vx-col">
              <label class="vs-input--label">{{ $t("MediaType") }}</label>
              <v-select
                label="Type"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="mediaType"
                :options="MediaTypes"
                class="w-full"
                autocomplete
                :reduce="(value) => value"
              />

              <label class="vs-input--label">photo</label>
              <!-- {{CourseQuestionBank.AttachedPicURL}} -->
              <vs-upload
                automatic
                :limit="1"
                :action="uploadUrl"
                :headers="UploadRequestHeader"
                @on-success="successUpload"
              />
            </div>
          </div>
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>

    <div>
      <div class="vx-row w-full">
        <div class="vx-col w-full">
          <vx-card v-bind:title="$t('Photos')">
            <vs-button
              type="border"
              class="mb-4 mt-4"
              icon-pack="feather"
              icon="icon-plus"
              @click="activePromptAttachment = true"
              >{{ $t("AddAttachment") }}
            </vs-button>
            <vs-table
              ref="table"
              pagination
              :max-items="20"
              :data="library.Attachments"
            >
              <template slot="thead">
                <vs-th>{{ $t("Download") }}</vs-th>
                <vs-th>{{ $t("MediaType") }}</vs-th>
                <vs-th>{{ $t("Actions") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td>
                      <a
                        v-if="
                          tr.AttachmentURL != '' && tr.AttachmentURL != null
                        "
                        :href="DownUrl + tr.AttachmentURL"
                        download
                        target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.MediaTypeName }}
                      </p>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                        @click.stop="openConfirmAttachment(tr)"
                      />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
import vSelect from "vue-select";

export default {
  components: {
    VuePerfectScrollbar,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      activePrompt: false,
      library: this.data,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
      activePromptAttachment: false,
      mediaTypeID: null,
      attachment: {},
      mediaType: {},
      dataForDeleted: {},
      listOfAttachment: [],
      uploadUrl: domain + "API/Common/UploadFile",
      img: "",
      DownUrl: domain,
    };
  },
  computed: {
    MediaTypes() {
      return this.$store.state.MediaTypes;
    },
    validateAttachmentForm() {
      return this.attachment.AttachmentURL == undefined;
    },
  },
  methods: {
    successUpload(event) {
      this.img = event.target.responseText;

      //window.showAddSuccess();
    },
    openConfirmAttachment(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlertAttachment,
      });
    },
    acceptAlertAttachment() {
      this.deleteAttachment(this.rowDataForDelete);
      this.$vs.notify({
        color: "danger",
        title: "Deleted record",
        text: "The selected record was successfully deleted",
      });
    },
    deleteAttachment(data) {
      if (data.ID > 0) {
        this.$store.dispatch("ArticleList/removeAttachment", data.ID);
      }

      const ItemIndex = this.library.Attachments.findIndex(
        (p) => p.AttachmentURL == data.AttachmentURL
      );
      //if(ItemIndex!=-1)
      this.library.Attachments.splice(ItemIndex, 1);
    },
    SaveAttachment() {
      if (this.img != null && this.img != "" && this.img != undefined) {
        this.attachment.MediaTypeID = this.mediaType.ID;
        this.attachment.MediaTypeName = this.mediaType.Type;
        this.attachment.AttachmentURL = this.img;
        this.library.Attachments.push(this.attachment);
      }

      // this.library.Attachments = this.listOfAttachment;
      //this.deletefacility(this.facility);
      // this.$store.commit("classRoomList/ADD_Facility", this.facility);
      this.attachment = {};
    },

    clearAttachmentModal() {
      this.attachment = {};
    },
  },
  created() {
    window.GetAllMediaTypes();
    console.log(this.library.Attachments);

    // if (this.library.StudentAttachments.length > 0) {
    //   this.listOfStudentAttachment = this.library.StudentAttachments;
    // }
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
