<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card v-show="done == false">
        <div slot="no-body">
          <div
            class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"
          >
            <div class="p-8">
              <div class="vx-card__title mb-8">
                <h4 class="mb-4">{{ $t("ChangePassword") }}</h4>
                <!-- <p>Please enter your new password.</p> -->
              </div>
              <vs-input
                class="w-full mb-base"
                label-placeholder="Old Password"
                v-model="Model.old_password"
              />
              <vs-input
                class="w-full mb-base"
                label-placeholder="New Password"
                v-model="Model.new_password"
              />
              <vs-input
                class="w-full mb-base"
                label-placeholder="Confirm Password"
                v-model="ConfirmPassword"
              />
              <div class="flex flex-wrap items-center justify-end">
                <vs-button @click="ChangePassword" class="ml-auto mt-2">{{
                  $t("SaveChanges")
                }}</vs-button>
                <vs-button
                  @click="cancle"
                  class="ml-4 mt-2"
                  type="border"
                  color="warning"
                  >{{ $t("Cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <div v-show="done == true" class="row">
        <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
          <div class="thankyou-wrap text-center">
            <h3 class="text-center p-3 mb-4">
              {{ $t("PasswordUpdated") }}
            </h3>
            <h4 class="text-muted text-center font-sm border-style">
              {{ $t("YourPasswordHasBeenUpdatedSuccessfully") }}
            </h4>

            <div class="col-md-9 text-center mt-4">
              <router-link class="navbar-brand" to="/">
                <button type="submit">
                  {{ $t("BackToHome") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      done: false,
      Model: {
        old_password: "",
        new_password: ""
      },
      ConfirmPassword: ""
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.Model.old_password !== "" &&
        this.Model.new_password !== "" &&
        this.Model.new_password == this.ConfirmPassword
      );
    }
  },

  methods: {
    cancle() {
      this.$router.go(-1);
    },
    ChangePassword() {
      debugger;
      this.$store
        .dispatch("auth/ChangePassword", this.Model)
        .then(() => {
          this.done = true;
        })
        .catch(() => {});
    }
  },
  created() {
    this.done = false;
  }
};
</script>
