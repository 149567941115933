/*=========================================================================================
  File Name: moduleLibraryAuthorActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
    addItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/LibraryAuthor/AddLibraryAuthor", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    fetchDataListItems({ commit }) {
        debugger
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get("api/LibraryAuthor/GetAll")
                .then(response => {
                    debugger
                    commit("SET_LibraryAuthors", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
            debugger
        });
    },
    updateItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/LibraryAuthor/UpdateLibraryAuthor", item)
                .then(response => {
                    commit("UPDATE_LibraryAuthor", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetItemByID(context, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/LibraryAuthor/GetLibraryAuthor?ID=" + itemid)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/LibraryAuthor/DeleteLibraryAuthor?ID=" + item.ID)
                .then(response => {
                    commit("REMOVE_ITEM", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GerAuthorsTree({ commit }) {
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get("api/LibraryAuthor/getAllAuthorsTree")
                .then(response => {
                    debugger
                    commit("SET_LibraryAuthorsTree", response.data.data);
                    debugger
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};