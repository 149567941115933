<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row w-full">
      <vx-card
        ref="filterCard"
        v-bind:title="$t('Filters')"
        class="user-list-filters mb-8"
        collapse-action
      >
        <div class="vx-col">
          <div class="vx-row flex">
            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{
                $t("LibraryCategory")
              }}</label>
              <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                label="Name"
                class="w-full"
                v-model="librarySearch.LibraryCategoryID"
                :options="LibraryCategories"
                autocomplete
                :reduce="(value) => value.ID"
              />
            </div>

            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{ $t("PublishDate") }}</label>
              <datepicker
                v-model="librarySearch.PublishDate"
                class="w-full"
                name="PublishDate"
                style="z-index: 9999 !important"
              ></datepicker>
            </div>
            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{ $t("IBSN") }}</label>
              <vs-input
                v-model="librarySearch.IBSN"
                class="w-full"
                name="IBSN"
              />
              <span class="text-danger text-sm" v-show="errors.has('IBSN')">{{
                errors.first("IBSN")
              }}</span>
            </div>
            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{
                $t("PrinterNumber")
              }}</label>
              <vs-input
                v-model="librarySearch.PrinterNumber"
                class="w-full"
                name="PrinterNumber"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('PrinterNumber')"
                >{{ errors.first("PrinterNumber") }}</span
              >
            </div>
          </div>
          <div class="vx-row flex">
            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{ $t("Name") }}</label>
              <vs-input
                v-model="librarySearch.Name"
                class="w-full"
                name="name"
              />
              <span class="text-danger text-sm" v-show="errors.has('name')">{{
                errors.first("name")
              }}</span>
            </div>
            <!-- Author -->
            <!-- <div class="flex-1" style="padding-left:5px">
              <label class="text-sm opacity-75">{{ $t("AuthorName") }}</label>
              <vs-input
                v-model="librarySearch.AuthorName"
                class="w-full"
                name="AuthorName"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('AuthorName')"
                >{{ errors.first("AuthorName") }}</span
              >
            </div> -->
            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{ $t("AuthorName") }}</label>
              <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                label="Name"
                class="w-full"
                v-model="librarySearch.AuthorID"
                :options="libraryAuthors"
                autocomplete
                :reduce="(value) => value.ID"
              />
            </div>
            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{ $t("PublishHouse") }}</label>
              <vs-input
                v-model="librarySearch.PublishHouse"
                class="w-full"
                name="PublishHouse"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('PublishHouse')"
                >{{ errors.first("PublishHouse") }}</span
              >
            </div>
            <!-- <div class="flex-1" style="padding-left:5px">
              <label class="text-sm opacity-75">{{ $t("Adress") }}</label>
              <vs-input
                v-model="librarySearch.Adress"
                class="w-full"
                name="Adress"
              />
              <span class="text-danger text-sm" v-show="errors.has('Adress')">{{
                errors.first("Adress")
              }}</span>
            </div> -->

            <div class="flex-1" style="padding-left: 5px">
              <label class="text-sm opacity-75">{{ $t("Index") }}</label>
              <vs-input
                v-model="librarySearch.Index"
                class="w-full"
                name="Index"
              />
              <span class="text-danger text-sm" v-show="errors.has('Index')">{{
                errors.first("Index")
              }}</span>
            </div>
          </div>
          <div class="vx-row flex"></div>

          <div class="flex flex-wrap mt-5 mb-3 float-right">
            <vs-button
              type="border"
              class="mr-6"
              color="danger"
              @click="resetModel"
              >{{ $t("Reset") }}</vs-button
            >
          </div>
          <div class="flex flex-wrap mt-5 mb-3 mr-5 float-right">
            <vs-button @click="search">{{ $t("Search") }}</vs-button>
          </div>
        </div>
      </vx-card>
    </div>

    <vs-table
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Libraries"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <vs-button
          type="border"
          class="ml-5"
          icon-pack="feather"
          icon="icon-plus"
          @click="editData()"
          >{{ $t("AddNew") }}</vs-button
        >

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Libraries.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Libraries.length
              }}
              of {{ Libraries.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Name De">{{ $t("NameAr") }}</vs-th>
        <vs-th sort-key="Name De">{{ $t("NameEn") }}</vs-th>
        <vs-th>{{ $t("Actions") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            @click="editData(tr.ID)"
          >
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.NameAr }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.NameEn }}</p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <router-link
                :to="{ name: 'Library-Edit', params: { ID: tr.ID } }"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
              >
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
              /></router-link>
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="openConfirm(tr)"
              />
            </vs-td>
          </tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleLibrary from "@/store/Library/library/moduleLibrary.js";
import moduleLibraryCategory from "@/store/Library/libraryCategory/moduleLibraryCategory.js";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moduleLibraryAuthor from "@/store/Library/libraryAuthor/moduleLibraryAuthor.js";

export default {
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
      librarySearch: {},
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Libraries() {
      return this.$store.state.LibraryList.Libraries;
    },
    LibraryCategories() {
      return this.$store.state.LibraryCategoryList.LibraryCategories;
    },
    libraryAuthors() {
      return this.$store.state.LibraryAuthorList.LibraryAuthors;
    },
  },

  methods: {
    resetModel() {
      this.librarySearch = {};
      this.search();
    },
    search() {
      this.$vs.loading();
      this.$store
        .dispatch("LibraryList/search", this.librarySearch)
        .then(() => this.$vs.loading.close())
        .catch(() => this.$vs.loading.close());
    },

    deleteData() {
      this.$vs.loading();

      this.$store
        .dispatch("LibraryList/removeItem", this.rowDataForDelete)
        .then(() => {
          window.showDeleteSuccess();
          this.$vs.loading.close();
        })
        .catch(() => this.$vs.loading.close());

      this.$store
        .dispatch("LibraryList/fetchDataListItems")
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },

    editData(ID) {
      this.$router.push({ name: "Library-Edit", params: { ID: ID } });
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.deleteData,
      });
    },
  },

  created() {
    this.$vs.loading();

    if (!moduleLibrary.isRegistered) {
      this.$store.registerModule("LibraryList", moduleLibrary);
      moduleLibrary.isRegistered = true;
    }
    this.$store
      .dispatch("LibraryList/fetchDataListItems")
      .then(() => {
        this.$vs.loading.close();
      })
      .catch(() => {
        this.$vs.loading.close();
      });
    if (!moduleLibraryCategory.isRegistered) {
      this.$store.registerModule("LibraryCategoryList", moduleLibraryCategory);
      moduleLibraryCategory.isRegistered = true;
    }

    if (!moduleLibraryAuthor.isRegistered) {
      this.$store.registerModule("LibraryAuthorList", moduleLibraryAuthor);
      moduleLibraryAuthor.isRegistered = true;
    }

    this.$store.dispatch("LibraryAuthorList/fetchDataListItems");
    this.$store.dispatch("LibraryCategoryList/fetchDataListItems");
    this.$vs.loading.close();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
