<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full">
        <label class="text-sm opacity-75">{{ $t("MainIndex") }}</label>
        <vs-textarea
          v-model="indexModel.MainIndex"
          class="w-full"
          name="MainIndex"
          placeholder="{المقدمة:1,الفصل الاول:10,الفصل الثاني:20}"
        >
        </vs-textarea>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <vs-button
          :disabled="!indexModel.MainIndex"
          type="border"
          class="mb-4 mt-6"
          icon-pack="feather"
          icon="icon-plus"
          @click="SaveAttachment"
          >{{ $t("AddMainIndex") }}
        </vs-button>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/4 w-full">
        <label class="text-sm opacity-75">{{ $t("SubjectAr") }}</label>
        <vs-input v-model="indexModel.NameAr" class="w-full" name="name" />
        <span class="text-danger text-sm" v-show="errors.has('SubjectAr')">{{
          errors.first("SubjectAr")
        }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <label class="text-sm opacity-75">{{ $t("SubjectEn") }}</label>
        <vs-input v-model="indexModel.NameEn" class="w-full" name="name" />
        <span class="text-danger text-sm" v-show="errors.has('SubjectEn')">{{
          errors.first("SubjectEn")
        }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <label class="text-sm opacity-75">{{ $t("PageNumber") }}</label>
        <vs-input
          type="number"
          v-model="indexModel.PageNumber"
          class="w-full"
          name="name"
        />
        <span class="text-danger text-sm" v-show="errors.has('PageNumber')">{{
          errors.first("PageNumber")
        }}</span>
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <vs-button
          :disabled="
            !(
              indexModel.PageNumber >= 0 &&
              indexModel.NameAr &&
              indexModel.NameEn
            )
          "
          type="border"
          class="mb-4 mt-4"
          icon-pack="feather"
          icon="icon-plus"
          @click="SaveAttachment"
          >{{ $t("Add") }}
        </vs-button>
      </div>
    </div>

    <div>
      <div class="vx-row w-full">
        <div class="vx-col w-full">
          <vx-card>
            <EditIndex style="background-color: transparent;" :showPopup="isPopupOpen" @close="closePopup"  @save-index="updateIndexList" :editIndex="editIndex"/>
            <vs-table ref="table" pagination :max-items="20" :data="IndexList">
              <template slot="thead">
                <vs-th>{{ $t("Subject") }}</vs-th>
                <vs-th>{{ $t("PageNumber") }}</vs-th>
                <vs-th>{{ $t("Actions") }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                  
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.Name }}
                      </p>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.PageNumber }}
                      </p>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                    
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click="openPopup(tr)"
              />
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                        @click.stop="openConfirmAttachment(tr)"
                        
                      />
                      
                    </vs-td>
                    
                  </vs-tr>
                </tbody>
                
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
import vSelect from "vue-select";
import EditIndex from "./EditIndex.vue";
// import AddEditIndex from './Add-Edit-Index.vue';

export default {
  components: {
    VuePerfectScrollbar,
    vSelect,
    // AddEditIndex,
    EditIndex
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      activePrompt: false,
      library: this.data,
      isPopupOpen: false,
      editIndex: null,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
      },
      activePromptAttachment: false,
      mediaTypeID: null,
      indexModel: {},
      mediaType: {},
      dataForDeleted: {},
      listOfAttachment: [],
      uploadUrl: domain + "API/Common/UploadFile",
      img: "",
    };
  },
  computed: {
    IndexList() {
      debugger;
      return this.library.LibraryIndexes;
    },
    validateAttachmentForm() {
      return this.indexModel.AttachmentURL == undefined;
    },
  },
  methods: {
    openConfirmAttachment(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlertAttachment,
      });
    },
    updateIndexList(updatedIndex) {
      // Find the index of the item to be updated in IndexList
      const index = this.IndexList.findIndex(item => item.id === updatedIndex.id);
      if (index !== -1) {
        // Update the item in IndexList with the updatedIndex data
        this.$set(this.IndexList, index, updatedIndex);
      }
    },
    openPopup(item) {
      this.editIndex = item;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
    },
   
    acceptAlertAttachment() {
      debugger;
      this.deleteAttachment(this.rowDataForDelete);
      this.$vs.notify({
        color: "danger",
        title: "Deleted record",
        text: "The selected record was successfully deleted",
      });
    },
    deleteAttachment(data) {
      debugger;
      if (data.ID > 0) {
        this.$store.dispatch("ArticleList/removeAttachment", data.ID);
      }
      debugger;
      const ItemIndex = this.library.LibraryIndexes.findIndex(
        (p) => p.Name == data.Name
      );
      //if(ItemIndex!=-1)
      this.library.LibraryIndexes.splice(ItemIndex, 1);
    },
    SaveAttachment() {
      debugger;
      if (this.indexModel.NameAr != null) {
        debugger;

        this.indexModel.Name = this.indexModel.NameAr;
        this.library.LibraryIndexes.push(this.indexModel);
        this.indexModel = {};
      } else if (this.indexModel.MainIndex) {
        debugger;
        var listOfIndexs = this.indexModel.MainIndex.split(",");
        for (var counter = 0; counter < listOfIndexs.length; counter++) {
          if (counter == 0) {
            listOfIndexs[counter] = listOfIndexs[counter].substring(1);
          } else if (counter + 1 == listOfIndexs.length) {
            debugger;
            listOfIndexs[listOfIndexs.length - 1] = listOfIndexs[
              listOfIndexs.length - 1
            ].slice(0, -1);
          }
          debugger
          var objIndex = listOfIndexs[counter].split(":");
          debugger
          this.indexModel.Name = objIndex[0];
          this.indexModel.NameAr= objIndex[0];
          this.indexModel.PageNumber = objIndex[1];

          this.library.LibraryIndexes.push(this.indexModel);
          this.indexModel = {};
        }
        // listOfIndexs[0];
        // debugger;
        // listOfIndexs[1];
        // debugger;
      }
    },
  },
  created() {
    console.log(this.library.LibraryIndexes);
    // debugger;
    // if (this.library.StudentAttachments.length > 0) {
    //   this.listOfStudentAttachment = this.library.StudentAttachments;
    // }
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
