import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"
import i18n from "../../../../i18n/i18n";

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init() {
    axios.interceptors.request.use(
      (config) => {
        config.headers = {
          language: i18n.locale,
          Authorization: "Bearer " + localStorage.getItem("token"),
        };

        //debugger;
        // Do something before request is sent
        //store.commit("changeLoadingStatus", true);
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch("auth/fetchAccessToken")
            .then((access_token) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = 'Bearer ' + access_token
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }

      return Promise.reject(error)
    })
  },
  login(email, pwd) {
    return axios.post("/API/Account/LoginAdmin", { UserName: email, Password: pwd })
  },
  registerUser(Name, email, pwd, role) {
    debugger
    return axios.post("/register", { Name: Name, email: email, password: pwd, isAdmin: true, RolesID: role })
  },
  // refreshToken() {
  //   return axios.post("/refresh", {refreshToken: localStorage.getItem("refreshToken")})
  // }
}
