/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
// import firebase from 'firebase/app'
import axios from "@/axios.js";

import "firebase/auth";
//import router from "@/router";
export default {
  // JWT
  loginJWT({ commit }, payload) {
    //debugger;
    return new Promise((resolve, reject) => {
      debugger;
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          debugger;
          // If there's user data in response
          if (response.data.status == "ok") {
            // Navigate User to homepage
            //   router.push(router.currentRoute.query.to || '/')

            // Set token
            debugger;
            localStorage.setItem("token", response.data.data.Token);
            localStorage.setItem("expiresIn", response.data.data.Expiration);
            //   localStorage.setItem("accessToken", response.data.accessToken)
            localStorage.setItem(
              "refreshToken",
              response.data.data.RefreshToken
            );

            // Update user details

            debugger;
            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.Token);
            //slocalStorage.setItem("TypeOfLogin", payload.TypeOfLogin)
            // to call local sotrage
            //localStorage.getItem('TypeOfLogin')
            resolve(response.data);
          } else {
            reject({ message: "Wrong Email or Password" });
          }
        })
        .catch(error => {
          //debugger;
          reject(error);
        });
    });
  },
  ChangePassword(context, model) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("/Account/ChangePassword", model)
        .then(response => {
          debugger;
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SendVerifyCodeForEmail(context, model) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post(
          "/Account/SendVerifyCodeForEmail?email=" +
            model +
            "&isResetPassword=" +
            true
        )
        .then(response => {
          debugger;
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  ResetPassword(context, model) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post(
          "/Account/ResetPassword?email=" +
            model.email +
            "&code=" +
            model.code +
            "&newPassword=" +
            model.newPassword
        )
        .then(response => {
          debugger;
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    debugger;
    const {
      Role,
      displayName,
      email,
      password,
      confirmPassword
    } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      jwt
        .registerUser(displayName, email, password,Role)
        .then(response => {
          debugger;
          // Update data in localStorage
          localStorage.setItem("token", response.data.token);
          commit("UPDATE_USER_INFO", response.data, { root: true });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAccessToken() {
    //debugger;
    return new Promise(resolve => {
      jwt
        .refreshToken()
        .then(response => {
          resolve(response);
        })
        .catch(function() {
          //debugger;
          //router.push("/pages/login");
        });
    });
  },
  logout({ commit }) {
    // Set accessToken
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    // Update user details
    commit("CLEAR_USER_INFO", null, { root: true });
    // Set bearer token in axios
    commit("REMOVE_BEARER");
  }
};
