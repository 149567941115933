var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout--main",class:[
    _vm.layoutTypeClass,
    _vm.navbarClasses,
    _vm.footerClasses,
    { 'app-page': _vm.isAppPage } ]},[_c('v-nav-menu',{staticClass:"text-white",attrs:{"navMenuItems":_vm.navMenuItems,"logo":_vm.navMenuLogo,"parent":".layout--main"}}),_c('div',{class:[_vm.contentAreaClass, { 'show-overlay': _vm.bodyOverlay }],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark } ],attrs:{"navbarType":_vm.navbarType,"logo":_vm.navMenuLogo}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e(),_c('h-nav-menu',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark } ],attrs:{"navMenuItems":_vm.navMenuItems}})]:[_c('the-navbar-vertical',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark } ],attrs:{"navbarColor":_vm.navbarColor}})],_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('div',{staticClass:"content-area__content"},[(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","right":_vm.$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px',"visibleoffset":"500"}},[_c('vs-button',{staticClass:"shadow-lg btn-back-to-top",attrs:{"icon-pack":"feather","icon":"icon-arrow-up"}})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{on:{"changeRouteTitle":_vm.changeRouteTitle}})],1)],1)])])]),_c('the-footer')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }