<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div>
      <div class="vx-row">
        <div class="vx-row w-full">
          <vx-card
            v-bind:title="$t('SinkarContentCategory')"
            class="mb-base ml-5 mr-5"
          >
            <div class="vx-col">
              <div class="vx-row flex ">
                <div class="flex-1" style="padding-left:5px">
                  <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
                  <vs-input
                    v-model="SinkarContentCategory.NameAr"
                    class="w-full"
                    name="name"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('name')"
                    >{{ errors.first("name") }}</span
                  >
                </div>
                <div class="flex-1" style="padding-left:5px">
                  <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
                  <vs-input
                    v-model="SinkarContentCategory.NameEn"
                    class="w-full"
                    name="name"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('name')"
                    >{{ errors.first("name") }}</span
                  >
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="mr-6"
              @click="submitData"
              :disabled="!isFormValid"
              >{{ $t("Save") }}</vs-button
            >
            <vs-button
              type="border"
              color="danger"
              :to="{ name: 'SinkarContentCategory' }"
              >{{ $t("Cancel") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleSinkarContentCategory from "@/store/Sinkar/SinkarContentCategory/moduleSinkarContentCategory.js";

export default {
  data() {
    return {
      SinkarContentCategory: {
        NameEn: "",
        NameAr: ""
      }
    };
  },
  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.SinkarContentCategory.NameAr &&
        this.SinkarContentCategory.NameEn
      );
    }
  },

  methods: {
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (
            this.SinkarContentCategory.ID !== null &&
            this.SinkarContentCategory.ID >= 0
          ) {
            this.$store
              .dispatch(
                "SinkarContentCategoryList/updateItem",
                this.SinkarContentCategory
              )
              .catch(() => {
                //console.error(err);
              });
            this.$router.push("/Sinkar/SinkarContentCategory");
          } else {
            this.$store
              .dispatch(
                "SinkarContentCategoryList/addItem",
                this.SinkarContentCategory
              )
              .catch(() => {
                //console.error(err);
              });
            this.$router.push("/Sinkar/SinkarContentCategory");
          }
        }
      });
    }
  },
  created() {
    if (!moduleSinkarContentCategory.isRegistered) {
      this.$store.registerModule(
        "SinkarContentCategoryList",
        moduleSinkarContentCategory
      );
      moduleSinkarContentCategory.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();

      this.$store
        .dispatch("SinkarContentCategoryList/GetItemByID", ID)
        .then(res => {
          this.SinkarContentCategory = res.data.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
          //console.error(err);
        });
    }
    this.$vs.loading.close();
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
