<!-- =========================================================================================
  File Name: Users.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="UsersList">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <vs-button type="border" icon-pack="feather" icon="icon-plus" @click="editData()">{{ $t("AddNew") }}</vs-button>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="
                    p-4
                    border border-solid
                    d-theme-border-grey-light
                    rounded-full
                    d-theme-dark-bg
                    cursor-pointer
                    flex
                    items-center
                    justify-between
                    font-medium
                  ">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                UsersList.length - currentPage * itemsPerPage > 0
                ? currentPage * itemsPerPage
                : UsersList.length
              }}
              of {{ UsersList.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="UserName">{{ $t("UserName") }}</vs-th>
        <vs-th sort-key="Email">{{ $t("Email") }}</vs-th>
        <vs-th>{{ $t("PhoneNumber") }}</vs-th>
        <vs-th sort-key="CreatedDate">{{ $t("Date") }}</vs-th>
        <vs-th>{{ $t("Actions") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" @click="editData(tr.Id)">
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.UserName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.Email }}</p>
            </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">
              {{ tr.PhoneNumber }}
            </p>
          </vs-td>
          <vs-td>
            {{ tr.CreatedDate | formatDate("yyyy/MM/dd HH:mm") }}
          </vs-td>
          <!-- <vs-td class="whitespace-no-wrap">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        @click.stop="editData(tr.Id)"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                        @click.stop="openConfirm(tr)"
                      />
                    </vs-td> -->
            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                @click.stop="openConfirm(tr)" />
            </vs-td>
          </tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleUser from "@/store/user/moduleUser.js";

export default {
  components: {},
  data() {
    return {
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    UsersList() {
      return this.$store.state.UserList.Users;
    },
  },

  methods: {
    editData(ID) {

      this.$router.push({ name: "User-edit", params: { ID: ID } });
    },
    deleteData() {
      this.$vs.loading();
      this.$store
        .dispatch("UserList/removeItem", this.rowDataForDelete.Email)
        .then(() => {
          window.showDeleteSuccess();
          this.$vs.loading.close();
          this.$store.dispatch("UserList/fetchDataListItems").then(() => {

            this.$vs.loading.close();
          });
        })
        .catch(() => this.$vs.loading.close());
      this.$store
        .dispatch("LibraryAuthorList/fetchDataListItems")
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.deleteData,
      });
    },
  },

  created() {
    this.$vs.loading();

    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
    this.$vs.loading.close();

    this.$store.dispatch("UserList/fetchDataListItems").then(() => {

      this.$vs.loading.close();
    });

    this.$store.dispatch("UserList/getAllRoles").then(() => {

      this.$vs.loading.close();
    });
    this.$vs.loading.close();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
