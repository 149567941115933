<!-- =========================================================================================
  File Name: UserEditTabSocial.vue
  Description: User Edit Social Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-prompt
      title="New Photo"
      accept-text="Save"
      @cancel="clearAttachmentModal"
      @accept="SaveAttachment"
      @close="clearAttachmentModal"
      :is-valid="validateAttachmentForm"
      :active.sync="activePromptAttachment"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent>
          <div class="mt-5 vx-row w-full " style="padding-left:20px">
            <div class="vx-col">
              <label class="vs-input--label">{{ $t("MediaType") }}</label>
              <v-select
                label="Type"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="mediaType"
                :options="MediaTypes"
                class="w-full"
                autocomplete
                :reduce="value => value"
              />

              <label class="vs-input--label">photo</label>
              <!-- {{CourseQuestionBank.AttachedPicURL}} -->
              <vs-upload
                automatic
                :limit="1"
                :action="uploadUrl"
                :headers="UploadRequestHeader"
                @on-success="successUpload"
              />
            </div>
          </div>
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>

    <div>
      <div class="vx-row w-full">
        <div class="vx-col w-full">
          <vx-card v-bind:title="$t('Photos')">
            <vs-button
              type="border"
              class="mb-4 mt-4"
              icon-pack="feather"
              icon="icon-plus"
              @click="activePromptAttachment = true"
              >{{ $t("AddAttachment") }}
            </vs-button>
            <vs-table
              ref="table"
              pagination
              :max-items="20"
              :data="Sermon.Attachments"
            >
              <template slot="thead">
                <vs-th>{{ $t("Download") }}</vs-th>
                <vs-th>{{ $t("MediaType") }}</vs-th>
                <vs-th>{{ $t("action") }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <tbody>
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td>
                      <a
                        :href="DownUrl + tr.AttachmentURL"
                        download
                        target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a>
                    </vs-td>
                    <vs-td>
                      <p class="product-name font-medium truncate">
                        {{ tr.MediaTypeName }}
                      </p>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="ml-2"
                        @click.stop="openConfirmAttachment(tr)"
                      />
                    </vs-td>
                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain, mainSetting } from "@/gloabelConstant.js";
import vSelect from "vue-select";

export default {
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      activePrompt: false,
      Sermon: this.data,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      activePromptAttachment: false,
      mediaTypeID: null,
      attachment: {},
      mediaType: {},

      dataForDeleted: {},
      listOfAttachment: [],
      uploadUrl: domain + "API/Common/UploadFile",
      img: ""
    };
  },
  computed: {
    MediaTypes() {
      debugger;
      return this.$store.state.MediaTypes;
    },
    validateAttachmentForm() {
      return this.attachment.AttachmentURL == undefined;
    }
  },
  methods: {
    openConfirmAttachment(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlertAttachment
      });
    },
    acceptAlertAttachment() {
      debugger;
      this.deleteAttachment(this.rowDataForDelete);
      this.$vs.notify({
        color: "danger",
        title: "Deleted record",
        text: "The selected record was successfully deleted"
      });
    },
    deleteAttachment(data) {
      debugger;
      if (data.ID > 0) {
        this.$store.dispatch("ArticleList/removeAttachment", data.ID);
      }
      debugger;
      const ItemIndex = this.Sermon.Attachments.findIndex(
        p => p.AttachmentURL == data.AttachmentURL
      );
      //if(ItemIndex!=-1)
      this.Sermon.Attachments.splice(ItemIndex, 1);
    },
    SaveAttachment() {
      debugger;
      this.attachment.MediaTypeID = this.mediaType.ID;
      this.attachment.MediaTypeName = this.mediaType.Type;
      this.attachment.AttachmentURL = this.img;
      this.listOfAttachment.push(this.attachment);
      this.Sermon.Attachments = this.listOfAttachment;
      //this.deletefacility(this.facility);
      // this.$store.commit("classRoomList/ADD_Facility", this.facility);
      this.attachment = {};
      this.img = "";
      this.mediaType = {};
    },

    clearAttachmentModal() {
      //   this.attachment = {}
    },

    successUpload(event) {
      debugger;
      // this.attachment.AttachmentURL = event.target.responseText;
      this.img = event.target.responseText;

      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    }
  },
  created() {
    window.GetAllMediaTypes();
    console.log(this.Sermon.Attachments);
    // debugger;
    // if (this.Sermon.StudentAttachments.length > 0) {
    //   this.listOfStudentAttachment = this.Sermon.StudentAttachments;
    // }
  }
};
</script>
