<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ------------------------------------------------ ----------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs
          class="tab-action-btn-fill-conatiner"
          style="margin-bottom:220px;"
        >
          <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <add-edit-main class="mt-4" :News="News" />
            </div>
          </vs-tab>

          <vs-tab
            :label="$t('Attachment')"
            icon-pack="feather"
            icon="icon-share-2"
          >
            <div class="tab-text">
              <add-edit-attachment class="mt-4" :data="News" />
            </div>
          </vs-tab>
          <!-- Save & Reset Button -->
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-end">
                <vs-button
                  class="ml-4 mt-2"
                  @click="submitData"
                  :disabled="!isFormValid"
                  >{{ $t("Save") }}
                </vs-button>
                <vs-button
                  class="ml-4 mt-2"
                  type="border"
                  color="danger"
                  :to="{ name: 'News' }"
                  >{{ $t("Cancel") }}</vs-button
                >
              </div>
            </div>
          </div>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moduleNews from "@/store/news/moduleNews.js";
// import { domain, mainSetting } from "@/gloabelConstant.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
import AddEditMain from "./Add-Edit-Main.vue";
import AddEditAttachment from "./Add-Edit-Attachment.vue";
export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
    Datepicker,
    AddEditMain,
    AddEditAttachment
  },
  data() {
    return {
      // baseURL: domain,
      // isMail: false,
      // UploadRequestHeader: mainSetting.UploadRequestHeader,
      // DownUrl: domain,
      selected: [],

      activePrompt: false,
      activePromptAttachment: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      News: {
        Attachments: []
      },
      dataForDeleted: {},
      edit: false,
      // ListOfContactTypes:[],
      itemsPerPage: 10,
      isMounted: false,
      // uploadUrl: domain + "API/Common/UploadFile",
      // Data Sidebar

      rowDataForDelete: {},
      activeConfirm: false,
      // IndustrySector:[],
      //  Language:[],

      attachment: {}
      // Nationality:[],
    };
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.News.TitleAr;
    },
    validateForm() {
      return !this.errors.any();
    }
  },

  methods: {
    successUploadNewsFile(event) {
      //debugger;
      this.News.FileAttachmentURL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    },
    initValues() {
      //debugger;
      this.News = {};
    },

    // SavePhoto()
    // {

    //       this.listOfAttachment.push(this.attachment);
    //      this.News.Attachments=this.listOfAttachment;
    //       this.attachment = {}
    // },

    //  clearContactModal() {
    //   this.attachment = {}
    // },

    openConfirm(data) {
      //debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      //debugger;
      this.deleteContact(this.rowDataForDelete);
    },

    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          if (this.News.ID !== null && this.News.ID >= 0) {
            this.$store
              .dispatch("NewsList/updateItem", this.News)
              .then(() => {
                this.$vs.loading.close();
                this.initValues();
                this.$router.push("/News");
              })
              .catch(() => this.$vs.loading.close());
          } else {
            this.$store
              .dispatch("NewsList/addItem", this.News)
              .then(() => {
                this.$vs.loading.close();
                this.initValues();
                this.$router.push("/News");
              })
              .catch(() => this.$vs.loading.close());
          }
        }
      });
    }
  },

  created() {
    //debugger;
    // this.getContactTypes();

    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$vs.loading();
      this.$store
        .dispatch("NewsList/GetItemByID", ID)
        .then(res => {
          this.News = res.data.data;
          if (this.News == undefined) {
            this.News = res[0].data;
          }
          this.listOfAttachment = this.News.Attachments;
          this.$vs.loading.close();

          //debugger;
        })
        .catch(err => {
          //debugger;
          if (err.response.status === 404) {
            this.user_not_found = true;
            return;
          }
          this.$vs.loading.close();

          //console.error(err);
        });
      this.$vs.loading.close();
    }
    if (!moduleNews.isRegistered) {
      this.$store.registerModule("NewsList", moduleNews);
      moduleNews.isRegistered = true;
    }
    this.$vs.loading.close();
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
