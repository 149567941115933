/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Articles.unshift(item);
  },
  SET_Articles(state, Articles) {
    debugger;
    state.Articles = Articles;
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_Article(state, Article) {
    debugger;
    const ArticleIndex = state.Articles.findIndex(p => p.ID == Article.ID);
    if (ArticleIndex != -1) {
      Object.assign(state.Articles[ArticleIndex], Article);
    }
  },
  REMOVE_ITEM(state, Article) {
    debugger;
    const ItemIndex = state.Articles.findIndex(p => p.ID == Article.ID);
    state.Articles.splice(ItemIndex, 1);
  }
};
