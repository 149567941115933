/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Katamars/AddKatamars", item)
        .then(response => {
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      //debugger;
      axios
        .get("api/Katamars/GetAll")
        .then(response => {
          commit("SET_Katamars", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/Katamars/UpdateKatamars", item)
        .then(response => {
          resolve(response);

          commit("UPDATE_Katamars", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID({ commit }, itemid) {
    //debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("api/Katamars/GetKatamars?ID=" + itemid)
        .then(response => {
          commit("UPDATE_Sinkar", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  removeItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/Katamars/DeleteKatamars?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  search({ commit }, item) {
    //debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("api/Katamars/SearchKatamarsWithPaging", item)
        .then(response => {
          commit("SET_Katamars", response.data.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
