/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.products.unshift(item);
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_PRODUCT(state, product) {
    debugger;
    const productIndex = state.products.findIndex(p => p.id == product.id);
    if (productIndex != -1) {
      Object.assign(state.products[productIndex], product);
    }
  },
  REMOVE_ITEM(state, itemId) {
    debugger;
    const ItemIndex = state.products.findIndex(p => p.Id == itemId);
    state.products.splice(ItemIndex, 1);
  },
  UPDATE_Reports(state, Reports) {
    state.reports = Reports;
  },
  // -----------------
  SET_USERS(state, users) {
    state.Users = users;
  },
  SET_ROLES(state, roles) {
    state.Roles = roles;
  },

};
