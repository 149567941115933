/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    i18n: "Home",
  },
  // {
  //   url: null,
  //   name: "Dashboard",
  //   // tag: "2",
  //   tagColor: "primary",
  //   icon: "CreditCardIcon",
  //   i18n: "Dashboard",
  //   submenu: [
  //     {
  //       url: "/dashboard/ecommerce",
  //       name: "eCommerce",
  //       slug: "dashboard-ecommerce",
  //       i18n: "eCommerce"
  //     }
  //   ]
  // },
  // Library
  {
    url: null,
    name: "Library",
    // tag: "2",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "Library",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "LibraryBook" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[1] == "LibraryBook" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[2] == "LibraryBook",
    // show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "LibraryBook",
    submenu: [
      {
        url: "/Library/LibraryAuthor",
        name: "LibraryAuthor",
        slug: "Library-LibraryAuthor",
        i18n: "LibraryAuthor",
      },
      {
        url: "/Library/LibraryCategory",
        name: "LibraryCategory",
        slug: "Library-LibraryCategory",
        i18n: "LibraryCategory"
      },
      {
        url: "/Library/Library",
        name: "Library",
        slug: "Library-Library",
        i18n: "Library"
      }
    ]
  },
  // Article
  {
    url: null,
    name: "Article",
    // tag: "2",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "Article",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Artical"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[1] == "Artical"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[2] == "Artical",
    submenu: [
      {
        url: "/Article/ArticleCategory",
        name: "ArticleCategory",
        slug: "Article-ArticleCategory",
        i18n: "ArticleCategory"
      },
      {
        url: "/Article/Article",
        name: "Article",
        slug: "Article-Article",
        i18n: "Article"
      }
    ]
  },
  // Sermon
  {
    url: null,
    name: "Sermon",
    // tag: "2",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "Sermon",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Sermon"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[1] == "Sermon"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[2] == "Sermon",
    submenu: [
      {
        url: "/Sermon/SermonCategory",
        name: "SermonCategory",
        slug: "Sermon-SermonCategory",
        i18n: "SermonCategory"
      },
      {
        url: "/Sermon/Sermon",
        name: "Sermon",
        slug: "Sermon-Sermon",
        i18n: "Sermon"
      }
    ]
  },
  // Sinkar
  {
    url: null,
    name: "Sinkar",
    // tag: "2",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "Sinkar",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Sinkar"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[1] == "Sinkar"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[2] == "Sinkar",
    submenu: [
      {
        url: "/Sinkar/SinkarCategory",
        name: "SinkarCategory",
        slug: "Sinkar-SinkarCategory",
        i18n: "SinkarCategory"
      },
      {
        url: "/Sinkar/SinkarContentCategory",
        name: "SinkarContentCategory",
        slug: "Sinkar-SinkarContentCategory",
        i18n: "SinkarContentCategory"
      },
      {
        url: "/Sinkar/Sinkar",
        name: "Sinkar",
        slug: "Sinkar-Sinkar",
        i18n: "Sinkar"
      },
      {
        url: "/Sinkar/SinkarRequest",
        name: "SinkarRequest",
        slug: "Sinkar-SinkarRequest",
        i18n: "SinkarRequest"
      },

    ]
  },
  // News
  {
    url: "/News",
    name: "News",
    slug: "News",
    icon: "GridIcon",
    i18n: "News",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "News"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[1] == "News"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[2] == "News",
  },
  // Katamars
  {
    url: null,
    name: "Katamars",
    // tag: "2",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "Katamars",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin" ||
      JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Katamars"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[1] == "Katamars"||
      JSON.parse(localStorage.getItem("userInfo")).Roles[2] == "Katamars",
    submenu: [
      {
        url: "/Katamars/KatamarsCategory",
        name: "KatamarsCategory",
        slug: "Katamars-KatamarsCategory",
        i18n: "KatamarsCategory"
      },
      {
        url: "/Katamars/Katamars",
        name: "Katamars",
        slug: "Katamars-Katamars",
        i18n: "Katamars"
      }
    ]
  },
  // Users
  {
    url: null,
    name: "Users",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "Users",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin",
    submenu: [
      {
        url: "/User/Users",
        name: "Users",
        slug: "Users",
        i18n: "Users"
      }
    ]
  },
  //Contact us
  {
    url: null,
    name: "ContactUs",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "ContactUs",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin",
    submenu: [
      {
        url: "/ContactUs/Masseges",
        name: "Masseges",
        slug: "Masseges",
        i18n: "Masseges",
      },
      {
        url: "/ContactUs/Complaints",
        name: "Complaint",
        slug: "Complaint",
        i18n: "Complaint"
      }
    ]
  },
  // SocialLinks
  {
    url: null,
    name: "AboutUs",
    tagColor: "primary",
    icon: "GridIcon",
    i18n: "AboutUs",
    show: JSON.parse(localStorage.getItem("userInfo")).Roles[0] == "Admin",
    submenu: [
      {
        url: "/AboutUs",
        name: "AboutUs",
        slug: "AboutUs",
        i18n: "AboutUs"
      }
    ]
  },

];
