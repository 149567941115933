/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    ADD_ITEM(state, item) {
        state.LibraryCategories.unshift(item);
    },
    SET_LibraryCategories(state, LibraryCategories) {
        debugger;
        state.LibraryCategories = LibraryCategories;
    },
    SET_LibraryCategoriesTree(state, LibraryCategories) {
        debugger;
        state.LibraryCategoriesTree = LibraryCategories;
    },
    // SET_LABELS(state, labels) {
    //   state.eventLabels = labels
    // },
    UPDATE_LibraryCategory(state, LibraryCategory) {
        const LibraryCategoryIndex = state.LibraryCategories.findIndex(
            p => p.ID == LibraryCategory.ID
        );
        if (LibraryCategoryIndex != -1) {
            Object.assign(
                state.LibraryCategories[LibraryCategoryIndex],
                LibraryCategory
            );
        }
    },
    REMOVE_ITEM(state, LibraryCategory) {
        const ItemIndex = state.LibraryCategories.findIndex(
            p => p.ID == LibraryCategory.ID
        );
        state.LibraryCategories.splice(ItemIndex, 1);
    }
};