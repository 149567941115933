/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Sinkars.unshift(item);
  },
  SET_Sinkars(state, Sinkars) {
    debugger;
    state.Sinkars = Sinkars;
  },
  UPDATE_Sinkar(state, Sinkars) {
    debugger;

    const SinkarsIndex = state.Sinkars.findIndex(p => p.ID == Sinkars.ID);
    if (SinkarsIndex != -1) {
      Object.assign(state.Sinkars[SinkarsIndex], Sinkars);
    }
  },
  REMOVE_ITEM(state, Sinkars) {
    const ItemIndex = state.Sinkars.findIndex(p => p.ID == Sinkars.ID);
    state.Sinkars.splice(ItemIndex, 1);
  }
};
