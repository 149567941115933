import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "./../router";

Vue.use(AclInstaller);
debugger;
let initialRole = "anonymous";
let userInfo = JSON.parse(localStorage.getItem("userInfo"));
debugger
// mkl
if (userInfo && userInfo.Roles) initialRole = userInfo.Roles[1];

export default new AclCreate({
  
  
  initial: initialRole,
  notfound: "/pages/login",
  router,
  acceptLocalRules: true,
  globalRules: {
    User: new AclRule("User")
      .or("Admin")
      .or("admin")
      .or("Customer")
      .or("LibraryBook")
      .or("Artical")
      .or("AudioLibrary")
      .or("VideoLibrary")
      .or("Sinkar")
      .or("News")
      .or("Katamars")
      .or("Sermon")
      .generate(),
    LibraryBook: new AclRule("LibraryBook").generate(),
    Artical: new AclRule("Artical").generate(),
    AudioLibrary: new AclRule("AudioLibrary").generate(),
    VideoLibrary: new AclRule("VideoLibrary").generate(),
    Sinkar: new AclRule("Sinkar").generate(),
    News: new AclRule("News").generate(),
    Katamars: new AclRule("Katamars").generate(),
    Sermon :  new AclRule("Sermon").generate(),
    Admin: new AclRule("Admin").generate(),
    editor: new AclRule("editor").or("User").generate(),
    anonymous: new AclRule("anonymous").or("User").or("editor").generate()
  }
});
