<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card v-bind:title="$t('MainInfo')" style="margin-bottom:20px">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Category") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            v-model="Article.ArticleCategoryID"
            :options="ArticleCategories"
            class="w-full"
            autocomplete
            :reduce="value => value.ID"
          />
          <span class="text-danger text-sm" v-show="errors.has('NameAr')">{{
            errors.first("NameAr")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
          <vs-input
            v-model="Article.NameAr"
            class="w-full"
            name="NameAr"
            :data-vv-as="$t('NameAr')"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('NameAr')">{{
            errors.first("NameAr")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
          <vs-input
            v-model="Article.NameEn"
            :data-vv-as="$t('NameEn')"
            class="w-full"
            v-validate="'required'"
            name="NameEn"
          />
          <span class="text-danger text-sm" v-show="errors.has('NameEn')">{{
            errors.first("NameEn")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AuthorNameAr") }}</label>
          <vs-input
            :data-vv-as="$t('AuthorNameAr')"
            v-validate="'required'"
            v-model="Article.AuthorNameAr"
            class="w-full"
            name="AuthorNameAr"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('AuthorNameAr')"
            >{{ errors.first("AuthorNameAr") }}</span
          >
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AuthorNameEn") }}</label>
          <vs-input
            :data-vv-as="$t('AuthorNameEn')"
            v-model="Article.AuthorNameEn"
            v-validate="'required'"
            class="w-full"
            name="AuthorNameEn"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('AuthorNameEn')"
            >{{ errors.first("AuthorNameEn") }}</span
          >
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label for="PublishDate" class="vs-input--label">
            {{ $t("ArticleDate") }}</label
          >
          <datepicker
            placeholder="Article Date"
            v-model="Article.ArticleDate"
            class="vx-col  w-full"
            name="ArticleDate"
          ></datepicker>
        </div>
      </div>

      <div class="vx-row">
        <!-- <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("ArticleAddressAr") }}</label>
          <vs-input
            :data-vv-as="$t('ArticleAddressAr')"
            v-model="Article.ArticleAdressAr"
            v-validate="'required'"
            class="w-full"
            name="ArticleAddressAr"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('ArticleAddressAr')"
            >{{ errors.first("ArticleAddressAr") }}</span
          >
        </div> -->

        <!-- <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("ArticleAddressEn") }}</label>
          <vs-input
            :data-vv-as="$t('ArticleAddressEn')"
            v-model="Article.ArticleAdressEn"
            v-validate="'required'"
            class="w-full"
            name="ArticleAddressEn"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('ArticleAddressEn')"
            >{{ errors.first("ArticleAddressEn") }}</span
          >
        </div> -->
        <div class="vx-col md:w-2/3 w-full">
          <label class="text-sm opacity-75">{{ $t("URL") }}</label>
          <vs-input
            v-model="Article.ArticleFileURL"
            class="w-full"
            name="name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('ArticleFileURL')"
            >{{ errors.first("ArticleFileURL") }}</span
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <p>{{ $t("BriefAr") }}</p>
          <vs-textarea v-model="Article.BriefAr" class="w-full" name="BriefAr">
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <p>{{ $t("BriefEn") }}</p>
          <vs-textarea v-model="Article.BriefEn" class="w-full" name="BriefEn">
          </vs-textarea>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <p>{{ $t("ArticleIndexAr") }}</p>
          <vs-textarea
            v-model="Article.ArticleIndexAr"
            class="w-full"
            name="ArticleIndexAr"
          >
          </vs-textarea>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <p>{{ $t("ArticleIndexEn") }}</p>
          <vs-textarea
            v-model="Article.ArticleIndexEn"
            class="w-full"
            name="ArticleIndexEn"
          >
          </vs-textarea>
        </div>
        <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-4" style="height: 30%;">
          <p>{{ $t("ArticleTextAr") }}</p>
          <vue-editor
            v-model="Article.ArticleTextAr"
            class="w-full"
            name="BriefAr" 
           
          />
        </div>
        <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-4" style="height: 30%;">
          <p>{{ $t("ArticleTextEn") }}</p>
          <vue-editor
            v-model="Article.ArticleTextEn"
            class="w-full"
            name="BriefAr"
            
          />
        </div>
      </div>
      <div id="data-list-list-view" class="data-list-container">
        <vs-prompt
          title="New File"
          accept-text="Save"
          @cancel="clearAttachmentModal"
          @accept="SaveAttachment"
          @close="clearAttachmentModal"
          :is-valid="validateAttachmentForm"
          :active.sync="activePromptAttachment"
        >
          <VuePerfectScrollbar
            class="scroll-area p-4"
            :settings="settings"
            :key="$vs.rtl"
          >
            <form @submit.prevent>
              <div class="mt-5 vx-row w-full " style="padding-left:20px">
                <div class="vx-col">
                  <label class="vs-input--label">{{ $t("File") }} </label>
                  <!-- {{CourseQuestionBank.AttachedPicURL}} -->
                  <vs-upload
                    automatic
                    :limit="1"
                    :action="uploadUrl"
                    :headers="UploadRequestHeader"
                    @on-success="successUpload"
                  />
                </div>
              </div>
            </form>
          </VuePerfectScrollbar>
        </vs-prompt>

        <label class="vs-input--label">{{ $t("File") }} </label>
        <div class="vx-row">
          <div>
            <vs-upload
              automatic
              :limit="1"
              :action="uploadUrl"
              :headers="UploadRequestHeader"
              @on-success="successUploadArticleFile"
            />
          </div>
          <div
            class="img-container w-64 mx-auto flex"
            v-if="Article.ArticleFileURL"
          >
            <!-- <img
                          :src="baseURL + Article.ArticleFileURL"
                          alt="img"
                          class="responsive"
                        /> -->
            <a :href="DownUrl + Article.ArticleFileURL" download target="_blank"
              ><feather-icon
                icon="DownloadIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
            /></a>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { VueEditor } from "vue2-editor";
import moduleArticle from "@/store/Article/article/moduleArticle.js";
import moduleArticleCategory from "@/store/Article/articleCategory/moduleArticleCategory.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
    Datepicker,
    VueEditor
  },
  props: {
    Article: {
      type: Object,
      required: true
    },
    viewLoginInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      DownUrl: domain,
      selected: [],
      activePromptAttachment: false,
      listOfAttachment: [],
      itemsPerPage: 10,
      isMounted: false,
      uploadUrl: domain + "API/Common/UploadFile",
      rowDataForDelete: {},
      activeConfirm: false,
      attachment: {},

      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      dataForDeleted: {}

      // Article: {
      //   Attachments: []
      // },
    };
  },
  computed: {
    validateAttachmentForm() {
      //debugger;
      return this.attachment.AttachmentURL == undefined;
    },
    getlistOfAttachment() {
      //debugger;
      return this.Article.Attachments;
    },

    isFormValid() {
      return (
        !this.errors.any() &&
        this.Article.NameAr &&
        this.Article.ArticleCategoryID > 0
      );
    },
    validateForm() {
      return !this.errors.any();
    },

    ArticleCategories() {
      return this.$store.state.ArticleCategoryList.ArticleCategories;
    }
  },
  methods: {
    openConfirmAttachment(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlertAttachment
      });
    },
    acceptAlertAttachment() {
      //debugger;
    },
    deleteAttachment(data) {
      //debugger;
      if (data.ID > 0) {
        this.$store.dispatch("ArticleList/removeAttachment", data.ID);
      }
      //debugger;
      const ItemIndex = this.Article.Attachments.findIndex(
        p => p.AttachmentURL == data.AttachmentURL
      );
      //if(ItemIndex!=-1)
      this.Article.Attachments.splice(ItemIndex, 1);
    },
    SaveAttachment() {
      //debugger;
      this.listOfAttachment.push(this.attachment);
      this.Article.Attachments = this.listOfAttachment;
      //this.deletefacility(this.facility);
      // this.$store.commit("classRoomList/ADD_Facility", this.facility);
      this.attachment = {};
    },

    clearAttachmentModal() {
      //   this.attachment = {}
    },

    successUpload(event) {
      //debugger;
      this.attachment.AttachmentURL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    },

    successUploadArticleFile(event) {
      //debugger;
      this.Article.ArticleFileURL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    },
    initValues() {
      //debugger;
      this.Article = {
        Attachments: []
      };
    },
    openConfirm(data) {
      //debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      //debugger;
      this.deleteContact(this.rowDataForDelete);
    },

    submitData() {
      //debugger;
    }
  },
  created() {
    if (!moduleArticle.isRegistered) {
      this.$store.registerModule("ArticleList", moduleArticle);
      moduleArticle.isRegistered = true;
    }

    if (!moduleArticleCategory.isRegistered) {
      this.$store.registerModule("ArticleCategoryList", moduleArticleCategory);
      moduleArticleCategory.isRegistered = true;
    }

    this.$store.dispatch("ArticleCategoryList/fetchDataListItems");
    this.$vs.loading.close();
  }
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.popup-example {
  border: none !important;
  z-index: 999999;
}
.MapClass {
  width: 640px;
  height: 430px;
}
</style>
