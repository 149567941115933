/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.SinkarRequests.unshift(item);
  },
  SET_SinkarRequest(state, SinkarRequests) {
    //debugger;
    state.SinkarRequests = SinkarRequests;
  },
  UPDATE_SinkarRequest(state, SinkarRequest) {
    debugger;
    const SinkarRequestIndex = state.SinkarRequests.findIndex(
      p => p.ID == SinkarRequest.ID
    );
    if (SinkarRequestIndex != -1) {
      Object.assign(state.SinkarRequests[SinkarRequestIndex], SinkarRequest);
    }
  },
  REMOVE_ITEM(state, SinkarRequest) {
    const ItemIndex = state.SinkarRequests.findIndex(
      p => p.ID == SinkarRequest.ID
    );
    state.SinkarRequests.splice(ItemIndex, 1);
  }
};
