/*=========================================================================================
  File Name: moduleComplaints.js
  Description: Complaints Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleContactUsState.js'
import mutations from './moduleContactUsMutations.js'
import actions from './moduleContactUsActions.js'
import getters from './moduleContactUsGetters.js'

export default {
    isRegistered: false,
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}