/*=========================================================================================
  File Name: moduleComplaintsActions.js
  Description: Complaints Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
    fetchComplaintListItems({ commit }) {
        // 1 ContactTypeID is Complaint Discus
        debugger
        return new Promise((resolve, reject) => {
            debugger;
            axios
                .get("api/ContactUs/GetAll?ContactTypeID=1")
                .then(response => {
                    debugger
                    commit("SET_ComplaintList", response.data.data);
                    debugger
                    resolve(response);
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
            debugger
        });
    },
    fetchMassegesListItems({ commit }) {
        // 2 ContactTypeID is Massege Text
        debugger
        return new Promise((resolve, reject) => {
            debugger;
            axios 
                .get("api/ContactUs/GetAll?ContactTypeID=2")
                .then(response => {
                    debugger
                    commit("SET_MassegeList", response.data.data);
                    resolve(response);
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
            debugger
        });
    },
    GetItemByID(context, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/ContactUs/GetContactByID?ID=" + itemid)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeComplaint({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/ContactUs/DeleteContact?ID=" + item.ID)
                .then(response => {
                    commit("REMOVE_Complaint", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeMasseges({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/ContactUs/DeleteContact?ID=" + item.ID)
                .then(response => {
                    commit("REMOVE_Massege", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};