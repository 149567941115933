/*=========================================================================================
  File Name: moduleLibraryAuthorMutations.js
  Description: LibraryAuthor Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {

    SET_MainInfo(state, MainInfo) {
        debugger;
        state.MainInfo = MainInfo;
    },

   
   
};