/*=========================================================================================
  File Name: moduleContactUsMutations.js
  Description: ContactUs Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    SET_ComplaintList(state, Complaints) {
        debugger;
        state.ComplaintList = Complaints;
        debugger;
    },
    SET_MassegeList(state, Masseges) {
        debugger;
        state.MassegesList = Masseges;
    },
    REMOVE_Complaint(state, Complaint) {
        const ItemIndex = state.Complaints.findIndex(
            p => p.ID == Complaint.ID
        );
        state.ComplaintList.splice(ItemIndex, 1);
    },
    REMOVE_Massege(state, Massege) {
        const ItemIndex = state.MassegesList.findIndex(
            p => p.ID == Massege.ID
        );
        state.MassegesList.splice(ItemIndex, 1);
    }
};