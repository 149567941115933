<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
    <vx-card v-bind:title="$t('MainInfo')" style="margin-bottom:20px">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Type") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="sermon.SermonTypeID"
            :options="SermonTypes"
            class="w-full"
            autocomplete
            :reduce="value => value.value"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("SermonCategory") }}</label>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            v-model="sermon.SermonCategoryID"
            :options="SermonCategories"
            class="w-full"
            autocomplete
            :reduce="ID => ID.ID"
          />
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
          <vs-input
            v-model="sermon.NameAr"
            class="w-full"
            name="name"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
          <vs-input v-model="sermon.NameEn" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AuthorNameAr") }}</label>
          <vs-input v-model="sermon.AuthorNameAr" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AuthorNameEn") }}</label>
          <vs-input v-model="sermon.AuthorNameEn" class=" w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
      </div>

      <div class="vx-row">
        <!-- <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressAr") }}</label>
          <vs-input
            v-model="sermon.SermonAdressAr"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("AdressEn") }}</label>
          <vs-input
            v-model="sermon.SermonAdressEn"
            class="w-full"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div> -->

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("Date") }}</label>
          <datepicker
            v-model="sermon.SermonDate"
            class="vx-col  w-full"
            name="SermonDate"
          ></datepicker>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("PublishDate") }}</label>
          <datepicker
            v-model="sermon.PublishDate"
            class="vx-col  w-full"
            name="PublishDate"
          ></datepicker>
        </div>
        <div class="vx-col md:w-2/3 w-full">
          <label class="text-sm opacity-75">{{ $t("URL") }}</label>
          <vs-input v-model="sermon.SermonFileURL" class="w-full" name="name" />
          <span
            class="text-danger text-sm"
            v-show="errors.has('SermonFileURL')"
            >{{ errors.first("SermonFileURL") }}</span
          >
        </div>
      </div>

      <div class="vx-row"></div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("BriefAr") }}</label>
          <vs-textarea v-model="sermon.BriefAr" class="w-full" name="BriefAr">
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("BriefEn") }}</label>
          <vs-textarea v-model="sermon.BriefEn" class="w-full" name="BriefEn">
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("IndexAr") }}</label>
          <vs-textarea
            v-model="sermon.SermonIndexAr"
            class="w-full"
            name="BookIndexAr"
          >
          </vs-textarea>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("IndexEn") }}</label>
          <vs-textarea
            v-model="sermon.SermonIndexEn"
            class="w-full"
            name="BookIndexEn"
          >
          </vs-textarea>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("TextAr") }}</label>
          <vs-textarea
            v-model="sermon.SermonTextAr"
            class="w-full"
            name="BriefAr"
          >
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label class="text-sm opacity-75">{{ $t("TextEn") }}</label>
          <vs-textarea
            v-model="sermon.SermonTextEn"
            class="w-full"
            name="BriefAr"
          >
          </vs-textarea>
        </div>
      </div>
      <div class="vx-col" v-if="sermon.SermonTypeID == 2">
        <label class="vs-input--label">{{ $t("File") }} </label>
        <div class="vx-row">
          <div>
            <vs-upload
              automatic
              :limit="1"
              :action="uploadUrl"
              :headers="UploadRequestHeader"
              @on-success="successUploadBookFile"
            />
          </div>
          <div
            class="img-container w-64 mx-auto flex"
            v-if="sermon.SermonFileURL"
          >
            <a :href="DownUrl + sermon.SermonFileURL" download target="_blank"
              ><feather-icon
                icon="DownloadIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
            /></a>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import moduleSermon from "@/store/Sermon/sermon/moduleSermon.js";
import moduleSermonCategory from "@/store/Sermon/sermonCategory/moduleSermonCategory.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";

// import axios from "@/axios.js";
export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
    Datepicker
  },
  props: {
    sermon: {
      type: Object,
      required: true
    },
    viewLoginInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      baseURL: domain,
      isMail: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      DownUrl: domain,
      selected: [],
      activePrompt: false,
      activePromptAttachment: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },

      SermonTypes: [],

      dataForDeleted: {},
      edit: false,
      listOfAttachment: [],
      // ListOfContactTypes:[],
      itemsPerPage: 10,
      isMounted: false,
      uploadUrl: domain + "API/Common/UploadFile",
      // Data Sidebar

      rowDataForDelete: {},
      activeConfirm: false,
      // IndustrySector:[],
      //  Language:[],

      attachment: {}
      // Nationality:[],
    };
  },
  computed: {
    SermonCategories() {
      return this.$store.state.SermonCategoryList.SermonCategories;
    }
  },
  methods: {
    setSermonTypes() {
      if (localStorage.getItem("SaveLang") === "ar") {
        this.SermonTypes = [
          { label: "فيديو", value: 1 },
          { label: "ملف", value: 2 }
        ];
      } else {
        this.SermonTypes = [
          { label: "Video", value: 1 },
          { label: "File", value: 2 }
        ];
      }
    },
    successUploadBookFile(event) {
      //debugger;
      this.sermon.SermonFileURL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    }
  },
  created() {
    if (!moduleSermon.isRegistered) {
      this.$store.registerModule("SermonList", moduleSermon);
      moduleSermon.isRegistered = true;
    }
    //debugger;
    this.$vs.loading.close();
    if (!moduleSermonCategory.isRegistered) {
      this.$store.registerModule("SermonCategoryList", moduleSermonCategory);
      moduleSermonCategory.isRegistered = true;
    }
    this.$store.dispatch("SermonCategoryList/fetchDataListItems");
    
    this.setSermonTypes();
    this.$vs.loading.close();

  }
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.popup-example {
  border: none !important;
  z-index: 999999;
}
.MapClass {
  width: 640px;
  height: 430px;
}
</style>
