<template>
    <div class="popup-overlay" v-if="showPopup">
      <div class="popup">
        <!-- <input type="text" class="popup-input" /> -->
        <div class="vx-row">
      <div class="vx-col md:w-3/4 w-full">
        <label class="text-sm opacity-75">{{ $t("SubjectEn") }}</label>
        <vs-input
        v-model="editIndex.Name"
          class="w-full"
          name="MainIndex"
        />
        <label class="text-sm opacity-75">{{ $t("PageNumber") }}</label>
        <vs-input
          type="number"
          v-model="editIndex.PageNumber"
          class="w-full"
          name="pagenumber"
        />
      </div>
        </div>
        <div class="popup-buttons">
          <button class="popup-save" @click="saveAndClose">{{$t("Save")}}</button>
          <button  class="popup-cancel" @click="closePopup">{{$t("Cancel")}}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import Add-Edit-Index from "./Add-Edit-Index.vue";
  export default {
    props: {
      showPopup: Boolean,
      editIndex: {},
    },
   
    methods: {
      saveAndClose() {
        debugger
          //in this section i want to update IndexList that in Add-edit-index.vue with editIndex
          this.$emit('save-index', this.editIndex);
          this.editIndex = {};
        
        this.$emit('close');
      },
      closePopup() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
 .popup-overlay {

  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  /* Set a higher z-index value */
  position: relative; /* Ensure proper positioning */
}

.popup {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  width: 400px; /* Increase popup size */
  position: relative; /* Use absolute positioning */

 
}

.popup-input {
  width: calc(100% - 20px);
  padding: 15px; /* Increase input text size */
  margin-bottom: 20px;
  border: 2px solid orange; /* Add border with orange color */
  border-radius: 5px; /* Add border radius */
  font-size: 16px; /* Increase font size */
}

.popup-buttons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the left */
}

.popup-save,
.popup-cancel {
  padding: 5px 10px;
  margin-right: 10px; /* Add margin between buttons */
  border: 2px solid orange; /* Add border with orange color */
  background-color: orange; /* Set background color to orange */
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.popup-save:hover,
.popup-cancel:hover {
  background-color: darkorange; /* Darken the background color on hover */
}
  </style>
  