<template>
  <div>
    <!-- v-validate="'required|email|min:3'" -->
    <vs-input
      v-validate="'required|min:3'"
      data-vv-validate-on="blur"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label-placeholder="Email"
      v-model="email"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("email") }}</span>
    <!-- <input id="defaultPopup" size="10" class="is-calendarsPicker"> -->
    <vs-input
      data-vv-validate-on="blur"
      v-validate="'required|min:6|max:10'"
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="Password"
      v-model="password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3"
        >Remember Me</vs-checkbox
      >
      <router-link to="/pages/forgot-password">Forgot Password?</router-link>
    </div>
    <div class="flex flex-wrap mb-3 float-right">
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()
        debugger;
        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    loginJWT() {
      //debugger;
      if (!this.checkLogin()) return;
      // Loading
      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
      };

      this.$store
        .dispatch("auth/loginJWT", payload)
        .then((res) => {
          //debugger;

          this.$vs.loading.close();
          debugger;
          for (var i = 0;i< res.data.Roles.length ;i++)
          {
            debugger
            this.$acl.change(res.data.Roles[i]);
          }
          debugger;
          //this.$router.currentRoute.query.to;
          debugger;
          this.$router.push("/");
        })
        .catch((Error) => {
          // if (Error.response.status == 404) {
          this.$vs.notify({
            title: "Error",
            text: Error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          //}
          this.$vs.loading.close();
        });
    },
  },
};
</script>
