<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="add-edit-main">
     <vx-card :title="$t('News')" class="mb-base ml-5 mr-5">
      <div class="vx-row  ">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("TitleAr") }}</label>
          <vs-input v-model="News.TitleAr" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("TitleEn") }}</label>
          <vs-input v-model="News.TitleEn" class="w-full" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>

        <div class="vx-col md:w-4/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("URL") }}</label>
          <vs-input
            v-model="News.fileAttachmentURL"
            class="w-full"
            name="name"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('fileAttachmentURL')"
            >{{ errors.first("fileAttachmentURL") }}</span
          >
        </div>

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("BriefAr") }}</label>
          <vs-textarea v-model="News.BriefAr" class="w-full" name="BriefAr">
          </vs-textarea>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("BriefEn") }}</label>
          <vs-textarea v-model="News.BriefEn" class="w-full" name="BriefEn">
          </vs-textarea>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DescriptionAr") }}</label>
          <vs-textarea
            v-model="News.DescriptionAr"
            class="w-full"
            name="BriefAr"
          >
          </vs-textarea>
        </div>

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DescriptionEn") }}</label>
          <vs-textarea
            v-model="News.DescriptionEn"
            class="w-full"
            name="BriefAr"
          >
          </vs-textarea>
        </div>
      </div>
      <div class="w-full">
        <!-- <Customer-Contact class="mt-4" :data="customerModel"/> -->
        <div id="data-list-list-view" class="data-list-container">
          <div>
            <div class="vx-row w-full">
              <div class="vx-col w-full">
                <div class="vx-col">
                  <label class="vs-input--label">{{ $t("File") }} </label>
                  <div class="vx-row">
                    <div>
                      <vs-upload
                        automatic
                        :limit="1"
                        :action="uploadUrl"
                        :headers="UploadRequestHeader"
                        @on-success="successUploadNewsFile"
                      />
                    </div>
                    <div
                      class="img-container w-64 mx-auto flex"
                      v-if="News.FileAttachmentURL"
                    >
                      <!-- <img
                          :src="baseURL + News.FileAttachmentURL"
                          alt="img"
                          class="responsive"
                        /> -->
                      <a
                        :href="DownUrl + News.FileAttachmentURL"
                        download
                        target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a>
                    </div>
                  </div>
                </div>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";

import moduleNews from "@/store/news/moduleNews.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";

// import axios from "@/axios.js";
export default {
  components: {
    vSelect,
    VuePerfectScrollbar,
    Datepicker
  },
  props: {
    News: {
      type: Object,
      required: true
    },
    viewLoginInfo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      uploadUrl: domain + "API/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      baseURL: domain,
      isMail: false,

      DownUrl: domain,
      selected: [],
      activePrompt: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      // News: {},
      dataForDeleted: {},
      edit: false,
      listOfAttachment: [],
      itemsPerPage: 10,
      isMounted: false,

      rowDataForDelete: {},
      activeConfirm: false,
      attachment: {}
    };
  },
  computed: {
  },
  methods: {
    successUploadBookFile(event) {
      //debugger;
      this.News.URL = event.target.responseText;
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Success upload  your Attachment"
      });
    }
  },
  created() {
    if (!moduleNews.isRegistered) {
      this.$store.registerModule("NewsList", moduleNews);
      moduleNews.isRegistered = true;
    }
    //debugger;
    this.$vs.loading.close();
  }
};
</script>

<style lang="css">
.selectExample {
  margin: 10px;
}
.con-select-example {
  display: flex;
  align-items: center;
  justify-content: center;
}
.con-select .vs-select {
  width: 100%;
}
@media (max-width: 550px) {
  .con-select {
    flex-direction: column;
  }
  .con-select .vs-select {
    width: 100%;
  }
}
.popup-example {
  border: none !important;
  z-index: 999999;
}
.MapClass {
  width: 640px;
  height: 430px;
}
</style>
