/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.SinkarCategories.unshift(item);
  },
  SET_SinkarCategory(state, SinkarCategories) {
    //debugger;
    state.SinkarCategories = SinkarCategories;
  },
  UPDATE_SinkarCategory(state, SinkarCategory) {
    debugger;
    const SinkarCategoryIndex = state.SinkarCategories.findIndex(
      p => p.ID == SinkarCategory.ID
    );
    if (SinkarCategoryIndex != -1) {
      Object.assign(
        state.SinkarCategories[SinkarCategoryIndex],
        SinkarCategory
      );
    }
  },
  REMOVE_ITEM(state, SinkarCategory) {
    const ItemIndex = state.SinkarCategories.findIndex(
      p => p.ID == SinkarCategory.ID
    );
    state.SinkarCategories.splice(ItemIndex, 1);
  }
};
